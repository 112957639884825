.content-item-type-image-text-products {
    .product-col {
        padding-top: 1rem;
        display: flex;
        justify-content: center;
        flex-flow: row wrap;

        .product-teaser {
            display: flex;
            // 2 cols
            flex: 0 0 50%
        }
    }
}

@include media-breakpoint-up(sm) {
    .content-item-type-image-text-products {
        .product-col {
            .product-teaser {
                // 3 cols
                flex: 0 0 33%
            }
        }
    }
}