/* Generic ---------------------------------------------------------------------------------------------------------- */
html,
body {
  color:              $standard-text-color;
  font-family:        $copy-text-font;
  font-weight:        $copy-text-weight;

  &.fpslider {
    padding-top: 0;
  }
}

.noscroll {
  overflow: hidden;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6 .h6 {
  font-family:        $headline-font;
  font-weight:        300;
}

a {
  color:              $standard-text-color;
  -webkit-transition: all .3s ease;
  transition:         all .3s ease;

  &:active,
  &:hover,
  &:focus,
  &:visited {
    color:              $standard-text-color;
    text-decoration:    none;
    -webkit-transition: all .3s ease;
    transition:         all .3s ease;
  }
}

p, td {
  -ms-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  font-size: 0.875rem;
}

button {
  &:focus {
    outline: none;
  }

  &.js-btn-catalog-load-more {
    background-color: $standard-text-color;
    border-radius: 0;
    color: $white;
    font-size: 0.875rem;
    padding-left: 1rem;
    padding-right: 1rem;
    text-transform: uppercase;
    transition: all ease 0.25s;

    &:hover {
      background-color: $beige;
      color: $standard-text-color;
      transition: all ease 0.25s;
    }
  }
}

:focus {
  outline:none;
}

::-moz-focus-inner {
  border: 0 !important;
}

textarea:focus,
input[type="text"]:focus {
  border-color: $beige;
  box-shadow: 0 1px 1px $light-beige inset, 0 0 8px $light-beige;
  outline: 0 none;
}

.cursor-pointer {
  cursor: pointer !important;
}

.search {
  .input-group {
    .form-control {
      border-color: $beige !important;
    }

    .btn-outline-secondary {
      border-color: $beige !important;
      border-left: 1px solid transparent;

      &:hover {
        background-color: $beige;
        color: $standard-text-color;
        border-left: 1px solid $beige;
      }
    }
  }
}

.btn-outline-secondary {
  border-color: rgb(206, 212, 218);
  color: $standard-text-color;
  text-transform: uppercase;
  font-family:        $headline-font;

  &:hover {
    background-color: $beige !important;
    border-color: $beige !important;
    color: $white !important;
  }
}

.product {
	.product-configurator-wrapper {
		.btn-outline-secondary {
		  background-color: $beige !important;
		  border-color: $beige !important;
		  color: $white !important;
		  text-transform: uppercase;
		  font-family:        $headline-font;
		
		  &:hover {
		    background-color: transparent !important;
		    border-color: rgb(206, 212, 218) !important;
			color: $standard-text-color !important;
		  }
		}
	}
}


.btn-rounded {
		position: relative;
		display: inline-block !important;
		border-radius: 25px !important;
		height: 40px !important;
		padding: 20px 50px !important;
		line-height: 0 !important;
		font-size: 1rem !important;
		border: 1px solid #2a2521 !important;
		text-transform: uppercase !important;
		letter-spacing: 0.15rem !important;
		font-weight: 300 !important;
		margin: 15px 0px 30px 0px;
		overflow: hidden;

		span {
			z-index: 1;
			position: relative;
		}
		
		&::before,
		&::after {
			content: '';
			width: 0;
			height:100%;
			position: absolute;
			right: 50%;
			top: 0;
			transition: width 0.3s;
			z-index: -1;
		}
		
		&::after {
			right: auto;
			left: 50%;
		}
		
		&-light {
			background-color: none !important;
	
			&:hover {
				color: #fff !important;
				background-color: transparent !important;
			}
			
			&::before,
			&::after {
				background-color: #2a2521;
			}
		}
		
		&-dark {
			background-color: #2a2521 !important;
			color: #fff !important;
			
			&:hover {
				color: #2a2521 !important;
			}
			
			&::before,
			&::after {
				background-color: #fff;
			}
		}
	
		&:hover::before,
		&:hover::after {
			width: 50%;
		}
}




.invert {
  color: $white !important;
}

/* XL --------------------------------------------------------------------------------------------------------------- */

/* LG --------------------------------------------------------------------------------------------------------------- */

/* MD --------------------------------------------------------------------------------------------------------------- */
@include media-breakpoint-down(md) {
  h1, .h1 {
    font-size: 1.75rem;
  }

  h2, .h2 {
    font-size: 1.5rem;
  }

  h3, .h3 {
    font-size: 1.25rem;
  }
}


/* SM --------------------------------------------------------------------------------------------------------------- */

/* XS --------------------------------------------------------------------------------------------------------------- */


