@import "history_item";
@import "history_item_video";

.history {
  .divider-hr {
    background-color: $standard-text-color;
  }

  .history-navigation {
    .nav-link {
      color: $beige;
      font-family: $copy-text-font;
      font-weight: normal;

      &.active {
        border-bottom: 2px solid $beige;
      }
    }
  }
}

/* XL --------------------------------------------------------------------------------------------------------------- */
@include media-breakpoint-only(xl) {
  .history {
  }
}

/* LG --------------------------------------------------------------------------------------------------------------- */
@include media-breakpoint-only(lg) {
  .history {
  }
}

/* MD --------------------------------------------------------------------------------------------------------------- */
@include media-breakpoint-only(md) {
  .history {
  }
}

/* SM --------------------------------------------------------------------------------------------------------------- */
@include media-breakpoint-only(sm) {
  .history {
  }
}

/* XS --------------------------------------------------------------------------------------------------------------- */
@include media-breakpoint-only(xs) {
  .history-navigation {
    .nav-link {
      color: $beige;
      font-weight: bold;
      font-size: .75rem;
      padding: 8px;

      &.active {
        border-bottom: 2px solid $beige;
      }
    }
  }

  .history {
  }
}