@import "content_item_image_text";
@import "content_item_image_image";
@import "content_item_text_text";
@import "content_item_image_text_product";
@import "content_item_image_text_video";

.content-item-text-block {
    p {
        a {
            color: inherit !important;
        }
    }
}


.content-item {
    &.item__text {
        max-width: 65ch;
    }
}

@include media-breakpoint-down(sm) {
	  .content-item-text-block {
		  .content-item-text {
		  		p {
			  		margin-top: 0.5rem !important;
			  		margin-bottom: 1rem !important;
		  		}
		  }
	  }
 }
