.newsroom_overview {
	
	.pagebody {
	    width: 935px;
	    max-width: 90vw;
	    margin: 0 auto;
	    padding: 50px 0;
    }

    * {
      color: #2a2521;
      font-family: Montserrat, Helvetica Neue, Arial, sans-serif;
      font-weight: 400;
      -webkit-font-smoothing: antialiased;
      -moz-osxfont-smoothing: grayscale;
      -moz-osxfont-smoothing: auto;
      -webkit-font-smoothing: auto;
      -ms-hyphens: auto;
      -moz-hyphens: auto;
      -webkit-hyphens: auto;
      hyphens: auto;
      hyphenate-limit-chars: 10 3 4;
    }
    
    .dFlex {
	    gap: 30px;
	    flex-wrap: wrap;
    }
    
    .flexBasis50 {
	    flex-basis: calc(50% - 15px);
    }
    
    .flexBasis33 {
	    flex-basis: calc((100% - 60px) / 3);
    }
    
    
    .article {
	    display: block;
	    position: relative;
	    background-color: #605953;
        background-image: url('/public/assets/img/sr-muster-001-seamless-pattern-04-03.gif');
        background-blend-mode: color-burn;
	    
	    * {
		    color: #e2dedf;
		    margin: 0;
		    hyphens: auto;
	    }
	    
	    .img_wrap {
		    width: 100%;
		    height:100%;
		    
		    &.landscape {
			    height: 60%;
			    
			    & ~.txt_wrap {
				    background: transparent !important;
			    }
		    }
	    }
	    img {
		    width: 100%;
		    height:100%;
		    object-fit: cover;
	    }
	    
	    .txt_wrap {
		    position: absolute;
		    bottom: 0;
		    left: 0;
		    width: 100%;
		    padding: 0 46px;
		    display: flex;
		    flex-direction: column;
		    background: rgb(46,23,29);
			background: linear-gradient(0deg, rgba(46,34,37,1) 0%, rgba(46,34,37,0) 100%);
		    
		    .title {
			    font-size: 38px;
			    line-height: 46px;
			    font-weight: 600;
			    text-transform: none !important;
			    margin-left: -2px;
		    }
		    
		    h3.title {
			    font-size: 24px;
			    line-height: 28px;
		    }
		    h4.title {
			    font-size: 19px;
			    line-height: 23px;
		    }
		    
		    .category,
		    .tags,
		    .topic {
			    font-size: 12px;
		    }	
	    }
	    
	    &.flexBasis100 {
		    
			width: 1600px;
			left: 50%;
			transform: translateX(-50%);
			max-width: 90vw;
			margin-bottom: 30px;
				    
		    .txt_wrap {
			    padding-top: 70px;
			    padding-bottom: 46px;
			    gap: 5px;
		    }
		    
		    .img_wrap {
			    &.landscape {
				    & ~.txt_wrap {
					    background: rgb(46,34,37) !important;
						background: linear-gradient(0deg, rgba(46,34,37,1) 0%, rgba(46,34,37,0) 100%) !important;
				    }
		    }
		    }
	    }

	    &.flexBasis50 {
		    aspect-ratio: 1/1;
		    
		    .txt_wrap {
			    padding-top: 18px;
			    padding-bottom: 18px;
			    gap: 5px;
		    }
	    }
	    
	    &.flexBasis33 {
		    aspect-ratio: 7/8;
		    
		    .txt_wrap {
			    padding-top: 18px;
			    padding-bottom: 18px;
			    gap: 5px;
		    }
	    }
    }

	
}

@include media-breakpoint-down(sm) {
	.newsroom_overview {
		.article {
			&.flexBasis50,
			&.flexBasis33 {
				aspect-ratio: 1/1;
				flex-basis: 100%;
			}
			
			.title {
			    font-size: 19px !important;;
			    line-height: 23px !important;;
		    }
		}
	}
}