$slick-font-path: "./fonts/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: black !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;
$slick-arrow-size: 40px;

@import '~slick-carousel/slick/slick.scss';
//@import '~slick-carousel/slick/slick-theme.scss';

.slick-slider {
    position: relative;
}

/* Arrows */
.js-slick-prev,
.js-slick-next {
    z-index: 500;
    left: 50%;
    height: $slick-arrow-size;
    width: $slick-arrow-size;
    border: 1px solid $white;
    border-radius: $slick-arrow-size;
    position: absolute;
    display: block;
    line-height: 0;
    cursor: pointer;
    background: $white;
    color: $body-color;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    transition: .3s ease-in-out;
    padding: 0;
    outline: none;
    opacity: 0.5;

    &:hover {
        outline: none;
        opacity: 1 !important;
    }

    &.slick-disabled {
        opacity: $slick-opacity-not-active;
    }

}

.js-slick-prev {
    left: $slick-arrow-size;
    right: auto;
}

.js-slick-next {
    right: $slick-arrow-size;
    left: auto;
}


.slick-dotted.slick-slider {
    margin-bottom: 30px;
}


/* Dots */
.slick-dotted.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    position: absolute;
    bottom: -25px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;

    li {
        position: relative;
        display: inline-block;
        height: 20px;
        width: 30px;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;

        button {
            border-left: 0;
            border-right: 0;
            display: block;
            height: 3px;
            width: 30px;
            outline: none;
            line-height: 0;
            font-size: 0;
            opacity: 0.25;
            color: transparent;
            cursor: pointer;
            background-clip: padding-box;
            background-color: $slick-dot-color;
            transition: .3s ease-in-out;
            padding-top: 2px;
            padding-bottom: 1px;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;

            &:hover, &:focus {
                outline: none;
                    opacity: $slick-opacity-on-hover;
            }
        }

        &.slick-active button {
            color: $slick-dot-color-active;
            opacity: $slick-opacity-default;
        }
    }
}




