main {
  .content-item {
    &.content-item-type-text-text {

      .item-1 {
        grid-area: text1;
        padding: 3rem 10rem;
      }

      .item-2 {
        grid-area: text2;
        padding: 3rem 10rem;
      }
    }
  }
}