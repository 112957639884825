.articles {
  background-image: url('/public/assets/img/sr-muster-001-seamless-pattern-04-03.gif');
  background-color: white;
  background-repeat: repeat;
}

/* müsste später in die allgemeine CSS */

.pagebody {
	ul {
		list-style-type: circle;
	}
}

#topbar {
	&.newsroom {
		background-color: #605956;
		
		svg {
			fill: #fdeee1;
			
			path {
				fill: #fdeee1;
			}
		}
		
		.backToNewsroomOverview {
				position: absolute;
				top: 50%;
				transform: translateY(-20px) translateX(-35%);
				transform-origin: left top;
				color: #fdeee1;
				font-size: 28px !important;
				font-weight: 600;
				font-family: Montserrat, Helvetica Neue, Arial, sans-serif;
				transition: all 0.25s ease;
				padding: 0 !important;
				z-index:10000;
		}
		
		figure {
			position: relative;
			
			
			svg {
				g {
					path {
						display: none;
						fill: #fdeee1 !important;
						
						&:last-of-type {
							display: block !important;
						}
					}
					rect {
						fill: #fdeee1 !important;
					}
				}
			}	
		}
	}
}

.small {
	#topbar {
		&.newsroom {
			background-color: #605956;
			
			svg {
				fill: #fdeee1;
				
				path {
					fill: #fdeee1;
				}
			}
			
			.backToNewsroomOverview {
				font-size: 28px !important;
				transform: scale(0.65) translateY(-18px) translateX(-35%);
			}
		}
	}
}

#articles-overview {



  .fixed-header {
    position: sticky;
  }

  .pagebody {
    max-width: 693px;
    margin: 0 auto;
    padding: 50px 0;

    * {
      color: #2a2521;
      font-family: Montserrat, Helvetica Neue, Arial, sans-serif;
      font-weight: 400;
      -webkit-font-smoothing: antialiased;
      -moz-osxfont-smoothing: grayscale;
      -moz-osxfont-smoothing: auto;
      -webkit-font-smoothing: auto;
      -ms-hyphens: auto;
      -moz-hyphens: auto;
      -webkit-hyphens: auto;
      hyphens: auto;
      hyphenate-limit-chars: 10 3 4;
    }

    h1 {
      font-weight: 500;
      font-size: 50px;
      line-height: 64px;
      margin-bottom: 26px;
    }

    h2 {
      font-weight: 600;
      font-size: 22px;
      line-height: 26px;
      margin-bottom: 25px;
      margin-top: 45px;
    }


    h3 {
      font-weight: 400;
      font-size: 25px;
      line-height: 34px;
      text-transform: none !important;
    }

    p {
      font-size: 18px;
      line-height: 28px;
    }

    .pagebody-text,
    .pagebody-text p {
      font-size: 18px;
      line-height: 28px;
      font-weight: 400 !important;

      strong {
        font-weight: 700;
        text-transform: uppercase;
        font-size: 80%
      }

      em {
        font-weight: 600;
        font-style: normal;
      }
    }

    .pagebody-location {
      font-weight: 700;
      text-transform: uppercase;
      font-size: 80%;
    }

    .category {
      display: block;
      font-size: 14px;
      margin: 0 0 26px 0;
    }

    figure {
      margin: 45px 0;


      .img-wrap {
        position: relative;
	    width: 120%;
	    left: 50%;
	    max-width: 90vw;
		transform: translateX(-50%);
		background-color: rgba(218, 217, 215, 0.2);

        picture {
	        width: 100%;
			height: 100%;
			display: flex;
        }


      
        img {
          position: relative;
          z-index: 10;
          margin: 0;
          width: 100%;
          height: auto;
          object-fit: cover;

        }
        /*
        img[src*=".png"] {
            display: block;
            margin: 0 auto;
            padding: 50px 0;
            max-width: calc(100% - 100px);
            object-fit: contain;
          }
          */

      }


      figcaption,
      figcaption p {
        font-size: 12px;
        font-weight: 500 !important;
        line-height: 16px;
        font-weight: 300;
        color: #545454;
        margin-top: 15px;

      }
    }


    blockquote {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      margin: 45px 0;
      width: 120% !important;
      max-width: 90vw;
      font-family: "Rollerscript W05 Smooth";
      font-size: 1.8rem;
      color: white;
      background-color: #2a2521;
      padding: 30px;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        background-color: #605953;
        background-image: url('/public/assets/img/sr-muster-001-seamless-pattern-04-03.gif');
        background-blend-mode: color-burn;
      }


    }
  }


  .img-gallery {
	left: 50%;
	max-width: 90vw;
	position: relative;
	transform: translateX(-50%);
	width: 120%;
	margin: 45px 0;

	}
	.dFlex {
		display: flex;
		gap: 15px;
		margin-bottom: 15px;
	}
	.flexEnd {
		align-items: flex-end;
	}
	.flexStart {
		align-items: flex-start;
	}

	.flexBasis33 {
		flex-basis: 33%;
	}

	.flexBasis50 {
		flex-basis: 50%;
	}

	.flexBasis66 {
		flex-basis: 66%;
	}
	.flexBasis100 {
		flex-basis: 100%;
	}

	.img-gallery-caption {
		padding: 15px 0;
		text-align: right;
		display: flex;
		flex-direction: column;
		align-items: flex-end;
	}

	.pagebody .img-gallery-caption h3 {
		font-size: 12px !important;
		line-height: 20px !important;
		text-transform: uppercase !important;
		font-weight: 500 !important;
		max-width: 400px;
	}

	.img-gallery-caption p {
		font-size: 12px !important;
		line-height: 20px !important;
		margin-bottom: 0 !important;
		max-width: 400px;
	}
	
	.child-w-100 {
		a {
			width: 100%;
		}
	}


}

@include media-breakpoint-down(md) {
	.hideOnTablet {
		display: none !important;
	}
}

@include media-breakpoint-down(sm) {

	
	.hideOnMobile {
		display: none !important;
	}

  #articles-overview {

	.img-gallery {
		.dFlex {
			flex-wrap: wrap;

			div {
				flex-basis: 100%;
			}
			
			&.flexEnd,
			&.flexStart {
				display: none !important;
			}
		}

		&-caption {
			text-align: left;
			align-items: flex-start;

			h3 {
				text-align: left;
				max-width: none;
			}

			p {
				text-align: left;
				max-width: none;
			}
		}
	}

    .pagebody {
      max-width: calc(100% - 40px);
      margin: 0 auto;
      padding: 50px 0;

      h1 {
        font-weight: 500;
        font-size: 28px;
        line-height: 34px;
        margin-bottom: 26px;
      }

      h2 {
        font-weight: 600;
        font-size: 22px;
        line-height: 25px;
      }

      h3 {
        font-weight: 400;
        font-size: 22px;
        line-height: 25px;
        text-transform: none !important;
      }

      p {
        font-size: 17px;
        line-height: 25px;
      }

      .pagebody-text,
      .pagebody-text p {
        font-size: 17px;
        line-height: 24px;
        font-weight: 400 !important;

        strong {
          font-weight: 700;
          text-transform: uppercase;
          font-size: 80%
        }

        em {
          font-weight: 600;
          font-style: normal;
        }
      }

      figure {
        margin: 45px 0;

        .img-wrap {
          position: relative;
          left: 0 !important;
          transform: translateX(-20px);
          width: 100vw !important;
          max-width: none;

          img {
            position: relative;
            z-index: 10;
            margin: 0;
          }

        }


        figcaption,
        figcaption p {
          font-size: 12px;
          font-weight: 500 !important;
          line-height: 16px;
          font-weight: 300;
          color: #545454;
          margin-top: 15px;

        }
      }
    }
  }
}
