.d-flex {
	display: flex;
	
	&.flexGap50 {
		gap: 50px;
	}
}

#product-request {
    margin-top: 110px;
    font-family: 'Montserrat-Regular', "Helvetica Neue", Arial, sans-serif;
    
    .productRequestImage {
	    margin-top: 50px;
    }

    .container {
      margin-top: 110px;
    }

    .form-control {
      height: 50px;
      border-radius: 6px;
      border: 1px solid #86868b;
      margin-bottom: 20px !important;
      
      &:focus {
	      box-shadow: none !important;
	      border: 1px solid #3579f6 !important
      }

      input {
        font-size: 17px;
      }

      &.invalid {
	      border-color:red;
	      color: red;
	      
	      &::placeholder {
		      color: red;
	      }
      }
    }
    
    .invalid_form_input {
	    color: red;
	    display: none;
    }
    
    
    
    select.form-control {
	    margin-bottom: 0 !important;
	    background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
	    background-position: calc(100% - 0.75rem) center !important;
	    -moz-appearance:none !important;
	    -webkit-appearance: none !important; 
	    appearance: none !important;
	    padding-right: 2rem !important;

        &.countries {
          width: auto !important;
        }
        
        &#ring-countries {
	        margin-bottom: 10px !important;
        }
    }
    
    textarea.form-control {
	    height: 125px;
    }

    .lbl-button {
	    position: relative;
        border-style: none;
        margin-right: 0;
        border-color: black;
        color: #3579f6;
        background-color: white;
        margin-top: 90px;

        &.lbl-button-next:after, &.lbl-button-prev:after {
	        content: '';
	        position: absolute;
	        top: 50%;
	        width: 10px;
	        height: 10px;
	        border-top: 3px solid #3579f6;
	        border-right: 3px solid #3579f6;
        }
        
        &.lbl-button-next {
	        padding-right: 20px;
	        &:after {
		        right: 0;
				transform: translateY(-50%) rotate(45deg);
	        }
        }
        
        &.lbl-button-prev {
	        padding-left: 20px;
	        &:after {
		        left: 0;
		        transform: translateY(-50%) rotate(-135deg);
	        }
        }
        
        &:hover {
	      color: black;
	      
	      &:after {
		      border-color: black;
	      }
        }
        
    }
    
    #submit.lbl-button {
	    position: relative;
        background-color: #3579f6;
        height: 40px;
        border-radius: 6px;
		text-indent: 0 !important;
		width: auto !important;
		color: #fff !important;
		padding-right: 35px !important;
		padding-left: 15px;
			  
		&.lbl-button-next {
			&:after {
			    left: auto !important;
			    right: 15px !important;
				transform: translate(-50%,-50%) rotate(45deg);
				border-top: 3px solid #fff !important;
		        border-right: 3px solid #fff !important;
			}
		}
	}

    input {
        font-size: 17px;
        margin-bottom: 10px !important;
    }

    select {
        font-size: 17px;
        margin-bottom: 10px !important;
    }

    h4 {
        font-family: Montserrat-SemiBold;
        font-size: 22px;
        text-transform: none !important;
    }

    .btn-white {
        background-color: white;
        color: black;
    }
    .product-request-text {
      font-size: 28px;
      line-height: 28px;
      font-family: 'Montserrat-SemiBold', "Helvetica Neue", Arial, sans-serif;

      .mainHeadline h1 {
        font-size: 32px;
        line-height: 28px;
        font-family: 'Montserrat-SemiBold', "Helvetica Neue", Arial, sans-serif;
        padding-bottom: 10px;
      }

      .showPrice {
        font-size: 14px;
        line-height: 16px;
        font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif;
        padding-left: 5px;
      }

      .besatzAnzahl {
        font-size: 13px;
        line-height: 18px;
        font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif;
        font-weight: 600;
        padding-left: 5px;
        margin-top: 20px;
      }
    }
    .productRequestUserData {

      .row {
        margin-top: 20px;
      }

      h4 {
      font-size: 22px;
      line-height: 28px;
      font-family: 'Montserrat-SemiBold', "Helvetica Neue", Arial, sans-serif;
      margin-bottom: 48px
      }
    }

    input {
      font-size: 17px;
      margin-bottom: 0 !important;
    }

    select {
      margin-bottom: 0 !important;
    }

    textarea {
      width: 100%;

    }


    @media only screen and (max-width: 1024px) {

    margin-top: 33px;

    }

    #contact-type, #comment-agb {
      select {
        margin-bottom: 10px !important;
      }
      input {
        margin-bottom: 10px !important;
      }
    }

  .checkbox-container {
    display: flex;
    align-items: flex-start;
    margin-top: 20px;
    
    label {
    	font-size: 12px;
    }
    
    input {
	    /*
	    -webkit-appearance: none;
		appearance: none;
		*/
	    width: 15px;
	    height: 15px;
	    border: 1px solid #3579f6;
	    margin-top: 2px;
	    border-radius: 6px;
	    flex-shrink: 0;
    }
  }

  /* Optional: Stilisierung der Checkbox */
  .checkbox-container input[type="checkbox"] {
    margin-right: 10px; /* Abstand zwischen Checkbox und Text */
  }

}

@media only screen and (max-width: 575px) {
	#product-request {
		margin-top: 10px;
		
		.col-sm-8, .col-xs-8 {
			width: calc((100%/12) * 8) !important;
		}
		.col-sm-4, .col-xs-4 {
			width: calc((100%/12) * 4) !important;
		}
		
		.col-lg-6, .col-lg-12 {
			padding-left: 0 !important;
			padding-right: 0 !important;
		}
		
		.mainHeadline h1 {
			font-size: 22px !important;
			padding-bottom: 0 !important;
		}
		
		select.form-control {
	        &.countries {
	          width: 100% !important;
	        }
	    }
	    
	    .d-flex.flexGap50 {
			justify-content: space-between;
			gap: 0;
		}
		
		.product-request-text {
			.w-100 {
				padding-left: 0 !important;
			}
		}
		
		.lbl-button {
		    position: relative;
	        text-indent: -5000px;
	        overflow: hidden;
	        background-color: #3579f6;
	        margin-top: 50px;
	        width: 80px;
	        height: 40px;
	        border-radius: 6px;
	
	        &.lbl-button-next:after, &.lbl-button-prev:after {
		        content: '';
		        position: absolute;
		        top: 50%;
		        right: auto;
		        left: 50%;
		        width: 10px;
		        height: 10px;
		        border-top: 3px solid #fff;
		        border-right: 3px solid #fff;
	        }
	        
	        &.lbl-button-next {
		        padding-right: 0;
		        &:after {
					transform: translate(-50%,-50%) rotate(45deg);
		        }
	        }
	        
	        &.lbl-button-prev {
		        padding-left: 0;
		        background-color: rgba(0,0,0,0.6);
		        &:after {
			        transform: translate(-50%,-50%) rotate(-135deg);
		        }
	        }
		}
		#submit.lbl-button {
			text-indent: 0 !important;
			  width: auto !important;
			  color: #fff !important;
			  padding-right: 35px !important;
			  padding-left: 15px;
			  
			  &.lbl-button-next {
		        &:after {
			        left: auto !important;
			        right: 15px !important;
					transform: translate(-50%,-50%) rotate(45deg);
		        }
	        }

		}
	}
}
