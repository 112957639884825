.item__navigation-image, .item__image {
  .image-overlay {
    background-color: rgba(0,0,0,.75);
    color: transparentize($white, .25);
    bottom: 0;
    display: none;
    left: 0;
    top: 0;
    right: 0;
    text-align: center !important;
  }


  &:hover {
    .image-overlay {
      font-family: $headline-font;
      transition: all .25s ease;
      visibility: visible !important;

      p {
        font-family: $copy-text-font;
        font-weight: 300;
        text-transform: uppercase;
      }

      .btn-cta {
        color: transparentize($white, .5);
        font-weight: 300;
        text-transform: uppercase;

        &:hover {
          color: transparentize($white, .25);
        }
      }
    }
  }

  .image-caption {
    font-size: .875rem;
    text-transform: uppercase;
  }
}