/* Colors ----------------------------------------------------------------------------------------------------------- */
$black:                     #000 !default;
$white:                     #FFF !default;
$beige:                     #B5ACA1 !default;
$light-beige:               #FCFBFA !default;

$standard-text-color:       #333 !default;
//$footer-background-color:   #191919 !default;
$footer-background-color:   #2a2521 !default;
$footer-text-color:         #efefef !default;

$muted-text-color:          rgba(0,0,0,.4) !default;

/* Typography ------------------------------------------------------------------------------------------------------- */
$font-path: '../static/fonts';
$font-family-sans-serif:  'Open Sans', 'Helvetica Neue', Arial, sans-serif;
$copy-text-font:        $font-family-sans-serif !default;
$headline-font:         'Montserrat', 'Helvetica Neue', Arial, sans-serif;
$copy-text-weight:      '400' !default;


