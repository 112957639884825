.sort-and-filter, #collapseFilters, #collapseSorters {
  border-bottom: 1px solid $standard-text-color;
  font-size: 0.75rem;
  padding-bottom: .5rem;
}

.catalog-filters {
  .nav-item {
    span {
      cursor: pointer;

      &:hover {
        color: $beige;
      }
    }
  }
}

.product-overlay-filter {
  top:0;
  right:0;
  bottom:0;
  left:0;
  background: radial-gradient(transparent,transparent,transparent,rgba(0,0,0,.01),rgba(0,0,0,.025),rgba(0,0,0,.05));
}

.catalog .overlay .overlay-image img {
	max-height: none;
	max-width: none;
}

.catalog .grid-item:hover div {
	-webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
	-moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
}

@include media-breakpoint-down(md) {
  .container.text {
	  .w-75 {
		  width: 100% !important;
	  }
  }
}
