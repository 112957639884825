.container {
    .container {
        max-width: none;
        margin: 0;
        padding: 0;
    }

    // Layout switch columns
    .content-item {
        @extend .row;

        &.ratio-6-6 {
            > div {
                @extend .col-lg-6;
            }

            > div + div {
                @extend .col-lg-6;
            }
        }

        &.ratio-4-8 {
            > div {
                @extend .col-lg-4;
            }

            > div + div {
                @extend .col-lg-8;
            }
        }

        &.ratio-3-9 {
            > div {
                @extend .col-lg-3;
            }

            > div + div {
                @extend .col-lg-9;
            }
        }

        &.ratio-8-4 {
            > div {
                @extend .col-lg-8;
            }

            > div + div {
                @extend .col-lg-4;
            }
        }

        &.ratio-5-7 {
            > div {
                @extend .col-lg-5;
            }

            > div + div {
                @extend .col-lg-7;
            }
        }

        &.ratio-9-3 {
            > div {
                @extend .col-lg-9;
            }

            > div + div {
                @extend .col-lg-3;
            }
        }

        &.ratio-7-5 {
            > div {
                @extend .col-lg-7;
            }

            > div + div {
                @extend .col-lg-5;
            }
        }

        &.image-position-top {
            > div {
                @extend .col-lg-12;

                width: 100% !important;
                max-width: 100% !important;
                flex-basis: 100%;
            }

            > div + div {
                @extend .col-lg-12;

                width: 100% !important;
                max-width: 100% !important;
                flex-basis: 100%;
            }
        }

        &.image-position-bottom {
            > div {
                @extend .col-lg-12;
                @extend .order-lg-2;

                width: 100% !important;
                max-width: 100% !important;
                flex-basis: 100%;
            }

            > div + div {
                @extend .col-lg-12;
                @extend .order-lg-1;

                width: 100% !important;
                max-width: 100% !important;
                flex-basis: 100%;
            }
        }

        &.right,
        &.image-position-right {
            > div {
                @extend .order-lg-2;
            }

            > div + div {
                @extend .order-lg-1;
            }
        }

        &.cg-0 {


            > div {
                padding-left: 0 !important;
                padding-right: 0 !important;
            }
        }

    }
}

