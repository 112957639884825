.overlay {
  background-color: #ffffff;
  margin: 0;
  display: none;
  z-index: 1030;
  box-shadow: 0 4px 6px 0 rgba(0,0,0,.05);
  border: 1px solid #999999;
  -webkit-transition: all .3s ease;
  transition:         all .3s ease;
  padding: .5rem !important;

  .overlay-text {
    padding: .75rem;
    font-size: .65rem;
    transition: all 0.5s ease-in;
    
    div {
	    margin-top: 0 !important;
    }
    
    p.grid {
	    margin-bottom: 0 !important;
	    height: auto !important;
    }

    .btn-sm {
      font-size: .65rem;
      text-transform: uppercase;
      background-color: $beige;
      border-color: $beige;

      &:hover {
        background-color: darken($beige, 10%);
        border-color: darken($beige, 10%);
      }
    }

    a {
      color: rgb(33,37,41);

      &:hover {
        color: rgb(33,37,41);
      }
    }
  }
  .overlay-image {
    overflow: hidden;
    -webkit-transition: all .3s ease;
    transition:         all .3s ease;
  }

  &.hover {
    -webkit-transition: all .3s ease;
    transition:         all .3s ease;
    display: grid;
  }
}

@include media-breakpoint-down(lg) {
  .overlay {
    margin: 0 !important;
  }
}

@media screen and (max-width: 1680px){
  .overlay {
    margin: 0 !important;
  }
}
