#contact-form .form-control  {
  margin: 3px;
}

.contactFormContainer {
	background-image: url(/public/assets/img/contactFormBG.jpg);
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	max-width: none !important;
	padding: 50px 15px 10px 15px !important;
	
	h2, p {
		text-align: center !important;
		color: rgba(255, 255, 255, 1) !important;
	}

}

.confirmContactForm {
	text-align: center;
}

.contactForm {
	.moduleContent {

		
		&.w-50 {
			min-width: 500px !important; 
			margin-left: auto;
			margin-right: auto;
			/* background-image: linear-gradient(0deg,#413835 35%,#8c827c) !important; */
						padding: 30px 50px 5px 50px;
			border-radius: 10px;
			margin-top: 50px;
			margin-bottom: 50px;
			
			input, select, textarea {
				background-color: transparent !important;
				border: 0;
				border-radius: 0;
				border-bottom: 1px solid rgba(255,255,255,1);
				color: #fff;
				padding-left: 0;
				resize: none;
				font-weight: 300 !important;
				
				&:focus {
					background-color: transparent !important;
					box-shadow: none !important;
					border: 0;
					border-bottom: 1px solid rgba(255,255,255,1);
				}
				&:placeholder-shown {
					background-color: transparent !important;
				}
			}
		}
	}
	
	#contact-form {
		.row {
			margin-bottom: 20px;
			gap: 20px;
			flex-wrap: nowrap;
			
			&.mb-10 {
				margin-bottom: 10px !important;
			}
			&.mt-10 {
				margin-top: 10px !important;
			}
		}
		
		.submitInput {
			margin-top: 20px;
			position: relative;
			display: inline-block !important;
			border-radius: 25px !important;
			height: 40px !important;
			padding: 20px 30px !important;
			line-height: 0 !important;
			font-size: 0.9rem !important;
			border: 1px solid #2a2521 !important;
			border: 0 !important;
			text-transform: uppercase !important;
			letter-spacing: 0.05rem !important;
			font-weight: 300 !important;
			margin: 15px 0px 15px 0px;
			overflow: hidden;
			background-color: #cca266 !important;
			color: #000 !important;
			z-index: 10 !important;
			white-space: nowrap !important;
			flex-basis: auto !important;
				
			span {
				z-index: 1;
				position: relative;
			}
			
			&::before,
			&::after {
				content: '';
				width: 0;
				height:100%;
				position: absolute;
				right: 49%;
				top: 0;
				transition: width 0.3s;
				z-index: -1;
			}
			
			&::after {
				right: auto;
				left: 49%;
			}
			
			
				&:hover {
					color: #000 !important;
					background-color: transparent !important;

					&::before,
					&::after {
						background-color: #635a56;
					}
				}
			
		
			&:hover::before,
			&:hover::after {
				width: 52%;
			}
		
		}
	}
	
	::-ms-input-placeholder { /* Edge 12-18 */
	  color: rgba(255,255,255,0.9);
	}
	
	::placeholder {
	  color: rgba(255,255,255,0.9);
	}
	
	#agb,
	#newsletter {
		width: 17px;
		height: 17px;
		border: 3px solid #444039;
		outline: 1px solid rgba(255,255,255,0.5);
		-webkit-appearance: none;
		margin-right: 10px !important;
		position: relative;
		top: 0px;
		display: inline-block;
		box-sizing: border-box;
		
		&:checked {
			background-color: #cca266 !important;
		}
	}
	
	label, p {
		color: rgba(255,255,255,1);
	}
	
}

@include media-breakpoint-down(sm) {
	.mobile-w100 {
		width: 100% !important;
	}
	
	.mobile-w50 {
		width: 50% !important;
	}
	
	.mobile-w0 {
		width: 0% !important;
	}

	.mobile-hide {
		display: none;
	}

	.contactFormWrap {
		width: 100% !important;
		margin-left: 0 !important;
		margin-right: 0 !important;
		
		.submitInput {
			margin-bottom: 25px !important;
		}
	}
	
	.contactForm {
		margin-top: 30px !important;
	}
	
	.contactForm .moduleContent.w-50 {
		padding: 20px 25px 0px 25px !important;
		width: 100% !important;
		min-width: 0 !important;
	}
	.mobile-wrap {
		flex-wrap: wrap !important;
	}
	
	#contact-form .container .content-item, .row {
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
}