#fos_user_registration_form_plainPassword_first,
#fos_user_registration_form_plainPassword_second {
  border-radius: 0 !important;
  line-height: 1.5;
  font-size: 0.875rem;
  padding: .25rem .5rem;
}

.account-sidebar {
  .nav-item {
    &.active {
      .nav-link {
        font-weight: 700;
      }
    }
  }
}