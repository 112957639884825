main {
    .content-item {
        &.content-item-type-image-text {

            .content-item-image {
                margin: 0 auto;

                img {

                    width: 100%;
                    max-width: none;
                }
            }

            .content-item-text-block {
                padding: 2rem 1rem !important;

                .content-item-headline {
                    h1, h2, h3 {
                        text-transform: uppercase;
                    }
                }

                .content-item-text {
                    p {
                        font-size: .875rem;
                        margin-top: 2rem;
                    }
                }
            }

            .content-item-teaser-headline {
                margin-top: 2rem;

                h6 {
                    font-family: $copy-text-font;
                    font-size: .75rem;
                    font-weight: bold;
                    margin-bottom: 0;
                    text-align: center;
                    text-transform: uppercase;
                    white-space: nowrap;
                }

                .headline-underline {
                    border-top: 1px solid $standard-text-color;
                    max-width: 150px;
                    margin: 3px auto 5px;
                    max-height: 3px;
                }
            }
        }
    }
    
    .animate-in {
	    transition: all 0.5s;
	    
	    &.visible {
			transform: translate(0%, 0%) !important;
			opacity: 1 !important;
		}
    }
    
    .image-position-right {
	    .animate-in {
		    transform: translate(100%, 0%);
		    opacity: 0;
	    }
    }
    .image-position-left {
	    .animate-in {
		    transform: translate(-100%, 0%);
		    opacity: 0;
	    }
    }
    .image-position-top {
	    .animate-in {
		    transform: translate(0%, 0%);
		    opacity: 0;
	    }
    }
}


@include media-breakpoint-down(sm) {
    main {
        .content-item {
            &.content-item-type-image-text {
	            &.image-position-left,
                &.image-position-right,
                &.image-position-top {
	                h2,
	                h3,
	                p {
		                text-align: left !important;
	                }
                }
            }
        }
    }
}


@include media-breakpoint-up(md) {
    main {
        .content-item {
            &.content-item-type-image-text {
                &.ratio-6-6,
                &.ratio-5-7,
                &.ratio-4-8,
                &.ratio-3-9 {
                    .content-item-text-block {
                        padding-left: 5rem !important;
                        padding-right: 5rem !important;
                    }
                }

            }
        }
    }
}

/* XL --------------------------------------------------------------------------------------------------------------- */
@include media-breakpoint-up(xl) {
    main {
        .content-item {
            &.content-item-type-image-text {
                .content-item-text-block {
                    padding-top: 0 !important;
                    padding-bottom: 0 !important;
                }
                &.image-position-top,
                &.image-position-bottom {
                    .content-item-text-block {
                        padding-top: 2rem !important;
                        padding-bottom: 2rem !important;
                    }
                }
                &.ratio-6-6,
                &.ratio-5-7,
                &.ratio-4-8,
                &.ratio-3-9 {
                    .content-item-text-block {
                        padding-left: 8rem !important;
                        padding-right: 8rem !important;
                    }

                    &.image-position-right,
                    &.image-position-left {
                        .content-item-text-block {
                            padding-left: 8rem !important;
                            padding-right: 8rem !important;
                        }
                    }
                }
            }
        }
    }
}

