@media screen {
  .thumbnails {
    cursor: pointer !important;
  }

  .product {
    display: grid;
    grid-template-rows: auto;
    grid-gap: 1rem;
    align-items: stretch;

    .product-configurator-wrapper {
      grid-area: configurator;
    }

    .thumbnails {
      grid-area: thumbnail;
      
      .d-flex {
      	align-items: center;
      }
    }

    .product-image-wrapper {
      grid-area: image;
    }
  }

  .product-description {
    font-size: .875rem;
    margin-bottom: 40px !important;
    
    p {
	    margin-bottom: 15px !important;
    }
  }

  .product-info {
    display: grid;
    grid-template-rows: auto;
    grid-gap: 1rem;
    align-items: stretch;

    td,th {
      border: 0;
    }

    th {
      font-size: 0.75rem;
      text-transform: uppercase;
    }

    tr {
      th {
        padding-left: 0;
        margin-left: 0;
        padding-top: 0;
        padding-bottom: 0;
      }

      td {
        font-size: .75rem;
        padding-top: 0px;
        padding-bottom: 2px;
        width: 50%;
      }
    }
  }

  @include media-breakpoint-only(xl) {
    .product {
      max-width: 1170px;
      margin: 0 auto;
      grid-template-columns: 150px auto 35%;
      grid-template-areas: "thumbnail image configurator";
    }

    .product-info {
      max-width: 1170px;
      margin: 0 auto;

      tr {
        td {
          width: 50%;
        }
      }
    }
  }

  @include media-breakpoint-only(lg) {
    .product {
      grid-template-columns: 150px auto 40%;
      grid-template-areas: "thumbnail image configurator";
    }
  }

  @include media-breakpoint-down(md) {
    .product {
      grid-template-columns: 1fr;
      grid-template-areas:
              "image"
              "thumbnail"
              "configurator"
    ;

      .product-image-wrapper {
        margin-top: .5rem;
      }
    }
  }

  .btn-shopping-cart {
    background-color: $beige !important;
    border-color: $beige !important;
    color: white !important;;

    &:hover {
      background-color: darken($beige, 10%) !important;
      border-color: darken($beige, 10%) !important;
      color: white !important;;
    }
  }
  
  @include media-breakpoint-down(sm) {
	  .thumbnails { display: none !important; } 
  }
}

@media print { 
	body {
		padding-top: 0 !important;
	}
	
	.shadow {
		box-shadow: none !important;
	}

	.form-group select {
		background: none;
        -webkit-appearance: none;
	}

	.thumbnails { display: none !important; } 
	
	.product-configurator-wrapper {
		width: 50% !important;
		float: left !important;
		
		hr {
			display: none !important;
		}
		
		.price.size-110 {
			width: 100% !important;
			padding-bottom: 35px !important;
			margin-bottom: 15px !important;
			border-bottom: 2px solid #000 !important;
		}
	}
	.product-image-wrapper {
		width: 50% !important;
		float: left !important;
	}
	
	.product + hr {
		clear: both;
		width: 100%;
		max-width: none !important;
		border-top: 2px dotted #444 !important;
	}
	.d-print-none {
		display: none !important;
	}
	.print-float {
		float: left !important;
		width: 32% !important;
	}
	
	.print-float.w-100 {
		width: 100% !important;
	}
	
	header.d-print-none {
		position: relative !important;
		height: auto !important;
		display: block !important;
		border: 0 !important;
		padding: 0 !important;
		
		.logo-wrapper {
		  max-width: 45% !important;
		}
		
		* {
			display: none;
		}
		
		.w-50.text-center {
			display: block !important;
			width: 100% !important;
			padding: 50px 0px 90px 0px !important;
			
			a {
				display: block !important;	

				* {
					display: block !important;				
				}
				#mini-logo {
					display: none !important;
				}
			}		
		}
		
	}
	
	header.d-print-none + div {
		display: none !important;
	}
	
	.product-description p {
	  margin-bottom: 15px !important;
	}
	
	.product-info {
		.table td, .table th {
			border: 0 !important;
		}
		
		hr {
			display: none !important;
		}
		
		h6.font-weight-bold.size-75 {
			margin: 50px 0px 20px 0px !important;
		}
		
		h6.text-uppercase {
			padding-bottom: 5px !important;
			border-bottom: 2px solid #000 !important;
			
		}
		
		.col-lg-4 {
			max-width: 33.33333333% !important;
		}
	}
	
	h6.text-muted.size-875.weight-500 {
		border-top: 2px dotted #444 !important;
		margin-top: 50px !important;
		padding-top: 35px !important;
		font-weight: bold !important;
		margin-bottom: 10px !important;
		width: 100% !important;
	}
}
