.content__fullpage-slider {
    .slick-slider,
    .slick-list,
    .slick-track,
    .slick-slide,
    .slick-slide > div,
    .slide {
        height: 100vh;
    }

    .slide {
        display: none;
        position: relative;

        &.active {
            display: block;
        }

        .slider-video {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

    .slick-dots {
        bottom: 25px;

        li button {
            background-color: rgb(239, 239, 239);
        }
    }

    .js-slick-prev, .js-slick-next {
        border-width: 0;
        background-color: transparent;
        color: #fff;
        height: 70%;
        border-radius: 0;
        cursor: pointer;
    }

    .js-slick-prev {
        left: 0;
        right: auto;
    }

    .js-slick-next {
        right: 0;
        left: auto;
    }

    .textbox {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        .text-content {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            /* height: 100vh; */
            height: auto;
            margin-bottom: 50px;
            
            h1, .h1 {
	            color: red !important;
	            
	            &:after {
		            content: "H1 nicht verwenden!";
	            }
            }
            
            h2, .h2 {
	            color: #fff !important;
            }
            
            h3, .h3,
            h4, .h4,
            h5, .h5,
            h6, .h6 {
                color: #B5ACA1 !important;
            }

        }
    }
    

    @include media-breakpoint-down(sm) {
        .textbox {
            .text-content {
                /* padding-bottom: 50px; */
                padding-top: 0 !important;
                h1, .h1 {
                    font-size: 1.5rem;
                }

                h2, .h2 {
                    font-size: 1.25rem;
                }

                h3, .h3,
                h4, .h4,
                h5, .h5,
                h6, .h6 {
                    font-size: 1rem;
                }
            }
        }
    }

    @include media-breakpoint-up(md) {
        .js-slick-prev, .js-slick-next {
            width: 140px;
        }

        .textbox {
            display: flex;
            flex-direction: column;

            &.top {
                justify-content: flex-start;
            }

            &.bottom {
                justify-content: flex-end;
            }
        }
    }
}

 /* änderungen am style des slick sliders */
    
.item__content-slider .slick-slide .card {
    position: relative;
}

.item__content-slider .slick-slide .card .card-img {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    background-color: transparent !important;
}

.item__content-slider .slick-slide .card .card-body {
    text-align: center;
}

/* ende änderungen am style des slick sliders */