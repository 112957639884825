.history {
  .history-item {
    .history-item-image {
      grid-area: image;
    }

    .history-item-text-block {
      grid-area: text;
      padding: 3rem 5rem;

      .history-item-year {
        color: $beige;
        font-weight: 500;
        margin-bottom: 2rem;
        text-align: center;

        h1,h2,h3,h4 {
          font-family: $copy-text-font;
          font-size: 1.25rem;
        }
      }

      .history-item-headline {
        h1,h2,h3 {
          text-align: center;
          text-transform: uppercase;
        }
      }

      .history-item-text {
        p {
          font-size: .875rem;
          margin-top: 2rem;
          text-align: center;
        }
      }
    }

    // Grid ------------------------------------------------------------------------------------------------------------
    display: grid;

    &.image-position-top {
      grid-template-columns: 1fr;
      grid-template-rows: minmax(0, auto) minmax(0, auto);
      grid-template-areas:
        'image'
        'text'
      ;
    }

    &.image-position-bottom {
      grid-template-columns: 1fr;
      grid-template-rows: minmax(0, auto) minmax(0, auto);
      grid-template-areas:
        'text'
        'image'
      ;
    }

    &.image-position-left,
    &.image-position-right {
      &.ratio-6-6 {
        grid-template-columns: 1fr 1fr;
      }

      &.ratio-5-7 {
        grid-template-columns: 5fr 7fr;
      }

      &.ratio-7-5 {
        grid-template-columns: 7fr 5fr;
      }

      &.ratio-4-8 {
        grid-template-columns: 4fr 8fr;
      }

      &.ratio-8-4 {
        grid-template-columns: 8fr 4fr;
      }

      &.ratio-3-9 {
        grid-template-columns: 3fr 9fr;
      }

      &.ratio-9-3 {
       grid-template-columns: 9fr 3fr;
      }
    }
  }
}

/* XL --------------------------------------------------------------------------------------------------------------- */
@include media-breakpoint-only(xl) {
  .history {
    .history-item {
      &.image-position-left {
        grid-template-rows: minmax(0, auto);
        grid-template-areas:
          'image text'
        ;
      }

      &.image-position-right {
        grid-template-rows: minmax(0, auto);
        grid-template-areas:
         'text image'
        ;
      }
    }
  }
}

/* LG --------------------------------------------------------------------------------------------------------------- */
@include media-breakpoint-only(lg) {
  .history {
    .history-item {
      &.image-position-left {
        grid-template-rows: minmax(0, auto);
        grid-template-areas:
          'image text'
        ;
      }

      &.image-position-right {
        grid-template-rows: minmax(0, auto);
        grid-template-areas:
          'text image'
        ;
      }
    }
  }
}

/* MD --------------------------------------------------------------------------------------------------------------- */
@include media-breakpoint-only(md) {
  .history {
    .history-item {
      &.image-position-left {
        grid-template-rows: minmax(0, auto);
        grid-template-areas:
          'image text'
        ;
      }

      &.image-position-right {
        grid-template-rows: minmax(0, auto);
        grid-template-areas:
          'text image'
        ;
      }

      &.image-position-left,
      &.image-position-right {
        &.ratio-6-6 {
          grid-template-columns: 1fr;
        }

        &.ratio-5-7 {
          grid-template-columns: 1fr;
        }

        &.ratio-7-5 {
          grid-template-columns: 1fr;
        }

        &.ratio-4-8 {
          grid-template-columns: 1fr;
        }

        &.ratio-8-4 {
          grid-template-columns: 1fr;
        }

        &.ratio-3-9 {
          grid-template-columns: 1fr;
        }

        &.ratio-9-3 {
          grid-template-columns: 1fr;
        }
      }

      .history-item-image {
        margin: 0 auto;
      }

      .history-item-text-block {
        padding: 3rem;
      }
    }
  }
}

/* SM --------------------------------------------------------------------------------------------------------------- */
@include media-breakpoint-only(sm) {
  .history {
    .history-item {
      &.image-position-left {
        grid-template-rows: minmax(0, auto) minmax(0, auto);
        grid-template-areas:
          'image'
          'text'
        ;
      }

      &.image-position-right {
        grid-template-rows: minmax(0, auto) minmax(0, auto);
        grid-template-areas:
          'image'
          'text'
        ;
      }

      &.image-position-left,
      &.image-position-right {
        &.ratio-6-6 {
          grid-template-columns: 1fr;
        }

        &.ratio-5-7 {
          grid-template-columns: 1fr;
        }

        &.ratio-7-5 {
          grid-template-columns: 1fr;
        }

        &.ratio-4-8 {
          grid-template-columns: 1fr;
        }

        &.ratio-8-4 {
          grid-template-columns: 1fr;
        }

        &.ratio-3-9 {
          grid-template-columns: 1fr;
        }

        &.ratio-9-3 {
          grid-template-columns: 1fr;
        }
      }

      .history-item-image {
        margin: 0 auto;
      }
    }
  }
}

/* XS --------------------------------------------------------------------------------------------------------------- */
@include media-breakpoint-only(xs) {
  .history {
    .history-item {
      &.image-position-left {
        grid-template-rows: minmax(0, auto) minmax(0, auto);
        grid-template-areas:
          'image'
          'text'
        ;
      }

      &.image-position-right {
        grid-template-rows: minmax(0, auto) minmax(0, auto);
        grid-template-areas:
          'image'
          'text'
        ;
      }

      &.image-position-left,
      &.image-position-right {
        &.ratio-6-6 {
          grid-template-columns: 1fr;
        }

        &.ratio-5-7 {
          grid-template-columns: 1fr;
        }

        &.ratio-7-5 {
          grid-template-columns: 1fr;
        }

        &.ratio-4-8 {
          grid-template-columns: 1fr;
        }

        &.ratio-8-4 {
          grid-template-columns: 1fr;
        }

        &.ratio-3-9 {
          grid-template-columns: 1fr;
        }

        &.ratio-9-3 {
          grid-template-columns: 1fr;
        }
      }

      .history-item-headline {
        h2 {
          font-size: 1.5rem;
        }
      }

      .history-item-year {
        margin-bottom: 0;
      }

      .history-item-text-block {
        font-size: .875rem;
        padding: 2rem 1rem;
      }
    }
  }
}