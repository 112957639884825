.offcanvas-left {
  .main-navigation {
    max-width: 20vw;
    min-width: 250px;
  }

  .background-beige {
    background-color: $light-beige;
  }

  .nav {
    .nav-item {
      .nav-link {
        font-size: .875rem;
        text-transform: uppercase;
      }

      &.nav-item-parent {
        font-family: $headline-font;
        margin-bottom: 2rem;
        text-transform: uppercase;

        .nav-link {
          font-weight: 500;
          padding: 0 0 .25rem 0;
        }
      }

      &.nav-item-child {
        font-family: $copy-text-font;

        .nav-link {
          color: transparentize($standard-text-color, .25);
          font-size: .75rem;
          font-weight: 400;
          padding: 0;

          &:hover {
            color: $standard-text-color;
          }
        }

        &.active {
          .nav-link {
            //color: $beige;
            text-decoration: underline;
          }
        }
      }
    }
  }

  .navigation-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 408px;
    overflow: auto;
  }

  .navigation-content {
    background-color: transparentize($black, .5);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: calc(100vw - 408px);
    overflow-y: scroll;
  }
}

@include media-breakpoint-down(sm) {
  .offcanvas-left {
    .nav {
      .nav-item {
        .nav-link {
          font-size: 1rem;
        }

        &.nav-item-child {
          .nav-link {
            font-size: .875rem;
            padding-top: 4px;
            padding-bottom: 4px;
          }
        }
      }
    }
  }
}