// @import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500|Open+Sans:300,400,500');

/* open-sans-300 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('#{$font-path}/open-sans-v23-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('#{$font-path}/open-sans-v23-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('#{$font-path}/open-sans-v23-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('#{$font-path}/open-sans-v23-latin-300.woff') format('woff'), /* Modern Browsers */
  url('#{$font-path}/open-sans-v23-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('#{$font-path}/open-sans-v23-latin-300.svg#OpenSans') format('svg'); /* Legacy iOS */
  font-display: swap;
}
/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('#{$font-path}/open-sans-v23-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('#{$font-path}/open-sans-v23-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('#{$font-path}/open-sans-v23-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('#{$font-path}/open-sans-v23-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('#{$font-path}/open-sans-v23-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('#{$font-path}/open-sans-v23-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
  font-display: swap;
}
/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('#{$font-path}/open-sans-v23-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('#{$font-path}/open-sans-v23-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('#{$font-path}/open-sans-v23-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('#{$font-path}/open-sans-v23-latin-600.woff') format('woff'), /* Modern Browsers */
  url('#{$font-path}/open-sans-v23-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('#{$font-path}/open-sans-v23-latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
  font-display: swap;
}

/* montserrat-300 - latin light */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url('#{$font-path}/montserrat-v18-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('#{$font-path}/montserrat-v18-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('#{$font-path}/montserrat-v18-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('#{$font-path}/montserrat-v18-latin-300.woff') format('woff'), /* Modern Browsers */
  url('#{$font-path}/montserrat-v18-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('#{$font-path}/montserrat-v18-latin-300.svg#Montserrat') format('svg'); /* Legacy iOS */
  font-display: swap;
}
/* montserrat-regular - latin regular */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('#{$font-path}/montserrat-v18-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('#{$font-path}/montserrat-v18-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('#{$font-path}/montserrat-v18-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('#{$font-path}/montserrat-v18-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('#{$font-path}/montserrat-v18-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('#{$font-path}/montserrat-v18-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
  font-display: swap;
}
/* montserrat-500 - latin medium */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('#{$font-path}/montserrat-v18-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('#{$font-path}/montserrat-v18-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('#{$font-path}/montserrat-v18-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('#{$font-path}/montserrat-v18-latin-500.woff') format('woff'), /* Modern Browsers */
  url('#{$font-path}/montserrat-v18-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('#{$font-path}/montserrat-v18-latin-500.svg#Montserrat') format('svg'); /* Legacy iOS */
  font-display: swap;
}
/* montserrat-600 - latin SemiBold */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('#{$font-path}/montserrat/Montserrat-SemiBold.ttf'); /* IE9 Compat Modes */
  src: local(''),
  url('#{$font-path}/montserrat/Montserrat-SemiBold.ttf') format('truetype'), /* Safari, Android, iOS */
}
/* montserrat-700 - latin Bold */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('#{$font-path}/montserrat/Montserrat-Bold.ttf'); /* IE9 Compat Modes */
  src: local(''),
  url('#{$font-path}/montserrat/Montserrat-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
}
/* montserrat-800 - latin Bold */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: url('#{$font-path}/montserrat/Montserrat-ExtraBold.ttf'); /* IE9 Compat Modes */
  src: local(''),
  url('#{$font-path}/montserrat/Montserrat-ExtraBold.ttf') format('truetype'), /* Safari, Android, iOS */
}
/* Rollerscript W05 Smooth - latin */
@font-face {
  font-family: 'Rollerscript W05 Smooth';
  font-style: normal;
  src: url('#{$font-path}/rollerscript/fonts/5314503/9e2e94ef-c960-47da-8ad3-92b25f1100f2.woff2'); /* IE9 Compat Modes */
  src: local(''),
  url('#{$font-path}/rollerscript/fonts/5314503/9e2e94ef-c960-47da-8ad3-92b25f1100f2.woff2') format('woff2'), /* Safari, Android, iOS */
  url('#{$font-path}/rollerscript/fonts/5314503/5c71d63f-69bf-488c-94c3-d1ef9c403006.woff') format("woff");
}

h1 {
  font-weight: 300;
  text-transform: uppercase;
}

h1,h2,h3,h4,h5,h6,
.h1,.h2,.h3,.h4,.h5,.h6 {
  text-transform: uppercase !important;
}

.text-beige {
  color: $beige !important;
}

.size-875 {
  font-size: .875rem !important;
}

.size-75 {
  font-size: .75rem !important;
}

.size-100 {
  font-size: 1rem !important;
}

.size-110 {
  font-size: 1.1rem !important;
}

.size-120 {
  font-size: 1.2rem !important;
}

.size-200 {
  font-size: 2rem !important;
}

.weight-500 {
  font-weight: 500 !important;
}
