.history {
  .history-item-type-video {
    &.image-position-top {
      grid-template-columns: 1fr;
      grid-template-rows: minmax(0, auto) minmax(0, auto);
      grid-template-areas:
        'image'
        'text'
      ;
    }

    &.image-position-bottom {
      grid-template-columns: 1fr;
      grid-template-rows: minmax(0, auto) minmax(0, auto);
      grid-template-areas:
        'text'
        'image'
      ;
    }

    .history-item-teaser-headline {
      margin-top: 2rem;

      h6 {
        color: $standard-text-color;
        font-family: $copy-text-font;
        font-size: .75rem;
        font-weight: bold;
        margin-bottom: 0;
        text-align: center;
        text-transform: uppercase;
        white-space: nowrap;
      }

      .headline-underline {
        border-top: 1px solid $standard-text-color;
        max-width: 150px;
        margin: 3px auto 5px;
        max-height: 3px;
      }
    }

    .history-item-teaser-video {
      margin: 0 auto;
      grid-area: video;

      .embed-responsive {
        margin: 0 auto;
      }
    }
  }
}

/* XXL -------------------------------------------------------------------------------------------------------------- */
@media screen and (min-width: 1920px) {
  .history {
    .history-item-type-video {
      &.image-position-left {
        grid-template-rows: minmax(0, auto);
        grid-template-areas:
                'image text'
      ;
      }

      &.image-position-right {
        grid-template-rows: minmax(0, auto);
        grid-template-areas:
                'text image'
      ;
      }

      .history-item-teaser-video {
        .embed-responsive {
          max-width: 10vw !important;
        }
      }
    }
  }
}

/* XL --------------------------------------------------------------------------------------------------------------- */
@include media-breakpoint-only(xl) {
  .history {
    .history-item-type-video {
      &.image-position-left {
        grid-template-rows: minmax(0, auto);
        grid-template-areas:
          'image text'
        ;
      }

      &.image-position-right {
        grid-template-rows: minmax(0, auto);
        grid-template-areas:
         'text image'
        ;
      }

      .history-item-teaser-video {
        .embed-responsive {
          max-width: 15vw;
        }
      }
    }
  }
}

/* LG --------------------------------------------------------------------------------------------------------------- */
@include media-breakpoint-only(lg) {
  .history {
    .history-item-type-video {
      &.image-position-left {
        grid-template-rows: minmax(0, auto);
        grid-template-areas:
          'image text'
        ;
      }

      &.image-position-right {
        grid-template-rows: minmax(0, auto);
        grid-template-areas:
          'text image'
        ;
      }

      .history-item-teaser-video {
        .embed-responsive {
          max-width: 20vw;
        }
      }
    }
  }
}

/* MD --------------------------------------------------------------------------------------------------------------- */
@include media-breakpoint-only(md) {
  .history {
    .history-item-type-video {
      &.image-position-left {
        grid-template-rows: minmax(0, auto) minmax(0, auto);
        grid-template-areas:
          'image'
          'text'
        ;
      }

      &.image-position-right {
        grid-template-rows: minmax(0, auto) minmax(0, auto);
        grid-template-areas:
          'image'
          'text'
        ;
      }

      .history-item-text-block {
        padding: 3rem;
      }

      .history-item-teaser-video {
        .embed-responsive {
          max-width: 25vw;
        }
      }
    }
  }
}

/* SM --------------------------------------------------------------------------------------------------------------- */
@include media-breakpoint-only(sm) {
  .history {
    .history-item-type-video {
      &.image-position-left {
        grid-template-rows: minmax(0, auto) minmax(0, auto);
        grid-template-areas:
          'image'
          'text'
        ;
      }

      &.image-position-right {
        grid-template-rows: minmax(0, auto) minmax(0, auto);
        grid-template-areas:
          'image'
          'text'
        ;
      }

      .history-item-teaser-video {
        .embed-responsive {
          max-width: 30vw;
        }
      }

      .history-item-teaser-headline {
        h6 {
          white-space: normal;
        }
      }
    }
  }
}

/* XS --------------------------------------------------------------------------------------------------------------- */
@include media-breakpoint-only(xs) {
  .history {
    .history-item-type-video {
      &.image-position-left {
        grid-template-rows: minmax(0, auto) minmax(0, auto);
        grid-template-areas:
          'image'
          'text'
        ;
      }

      &.image-position-right {
        grid-template-rows: minmax(0, auto) minmax(0, auto);
        grid-template-areas:
          'image'
          'text'
        ;
      }

      .history-item-teaser-video {
        .embed-responsive {
          max-width: 35vw;
        }
      }

      .history-item-teaser-headline {
        h6 {
          white-space: normal;
        }
      }
    }
  }
}