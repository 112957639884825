#search {

	#filter-form {
		border-bottom: 1px solid rgba(51, 51, 51, 0.5);
		padding-bottom: 20px; 
	}

  .filter-tag {
    border-style: none;
    background-color: #B5ACA1;
    border-width: 1px;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 10px;
    font-size: 12px;
    color: white;;
    cursor: pointer;
    padding-left: 35px;
    padding-right: 15px;
    border-radius: 3px;
    position: relative;
    
    span {
	    display: none;
    }
    
    &:before {
	  content: '×';
	  position: absolute;
	  top: 50%;
	  left: 10px;
	  transform: translateY(-50%);
	  font-size: 20px;
	  color: rgba(255,255,255,1) !important;
    }
  }

  .filter-tag:hover {

    background-color: #B5ACA1;
    color: rgba(255,255,255,0.5);
  }

  .dropdown {
    font-size: 13px;
    margin-right: 5px;
    min-width: 150px;
  }

  select {
    width: auto; /* Standardbreite für Desktop */

  }

  @media only screen and (max-width: 768px) {
    select {

      width: 100%; /* 100% Breite in der mobilen Ansicht */
    }
  }

}

