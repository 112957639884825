@import "navigation_image";

.js-btn-close {
  color: $beige !important;
}

/* Navigation & Sidebar --------------------------------------------------------------------------------------------- */
#sidebar {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: all 1s ease;
  overflow-y: scroll;
  display: none;

  &.visible {
    display: block;
    transition: all 1s ease;
    z-index: 1090;
    overflow-y: scroll;
  }
}

.overlay-page-header {
  h1,h2,h3,h4,h5,h6 {
    text-align: center;
    text-transform: uppercase;
  }

  h1 {
    font-family: $headline-font;
  }

  h4 {
    color: $beige;
    font-family: $headline-font;
    font-size: 1.25rem;
    font-weight: 500;
  }

  .overlay-page-teaser {
    font-size: 1rem;
    margin: 0 auto;
    max-width: 800px;
    text-align: center;
  }
}