.spacer-1 {
  min-height: 1rem;
}

.spacer-2 {
  min-height: 2rem;
}

.spacer-3 {
  min-height: 3rem;
}

.spacer-4 {
  min-height: 4rem;
}

.spacer-5 {
  min-height: 5rem;
}

.noscroll {
  overflow: hidden;
}

.bg-white {
  background-color: $white !important;
}

.img-objectfit {
  width: 100%;
  height: 100%;
  object-fit: cover;
}