$slick-arrow-size: 40px;

.item__content-slider {
    padding-top: 2rem;
    padding-bottom: 2rem;

    .slick-content-slider {
        // before slider is initialized
        display: flex;
        flex-wrap: nowrap;
        opacity: 0.15;
        transition: .3s ease-in-out;

        &.slick-initialized {
            opacity: 1;
        }
    }

    .slick-track,
    .slick-list {
        margin:auto;
    }

    // slide / card design
    .slide,
    .slick-slide {
        width: 100%
    }

    .slick-slide {
        .card {
            padding-left: 5px;
            padding-right: 5px;
            border: none;
            border-radius: 0;
        }

        .card-img {
            background-color: $black;
        }

        .card-img-top {
            opacity: 1;
            transition: .3s ease-in-out;
            border-radius: 0;
        }

        &:hover {
            .card-img-top {
                opacity: .75 !important;
            }
        }

        .card-title {
            @extend .h5;
            font-weight: 300;
            text-transform: uppercase;
        }
    }

    // variant with faded images
    &.fade-next {
        @include media-breakpoint-up(lg) {
            .slide,
            .slick-slide {
                .card-img {
                    width: 348px;
                    height: 348px;
                    overflow: hidden;
                    background-color: $white;

                    .card-img-top {
                        opacity: 0.5;
                    }
                }

                &.slick-active {
                    .card-img {
                        background-color: $black;

                        .card-img-top {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    @include media-breakpoint-up(md) {
        .slide,
        .slick-slide {
            width: 350px;
        }
    }

    // Arrows design
    .js-slick-prev {
        left: 0;
        right: auto;
        opacity: 0;
        transition: .3s ease-in-out;
    }

    .js-slick-next {
        right: 0;
        left: auto;
        opacity: 0;
        transition: .3s ease-in-out;
    }

    &:hover {
        .js-slick-prev {
            opacity: 0.5;
            left: $slick-arrow-size;
        }

        .js-slick-next {
            opacity: 0.5;
            right: $slick-arrow-size;
        }
    }
}
