.masonry-grid-item {
  width: 30%;
  margin-bottom: 10px;
}
.gutter-sizer { width: 1%; }
.masonry-grid-item--width2 {
  width: 60%;
}

.masonry-grid-item--width100 {
  width: 91.5%;
}


.masonry-grid-item--width50 {
  width: 45%;
}

