.tab-navigation, .anchor-navigation {
  .nav-link {
    color: $beige;
    font-family: $copy-text-font;
    font-weight: normal;
    text-transform: uppercase;

    &.active {
      border-bottom: 2px solid $beige;
    }
  }
}
