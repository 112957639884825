.dealers {	
	h4 {
		margin-bottom: 5px;
	}
	
  table {
    th {
      font-size: 0.75rem !important;
      padding-top: 20px;
    }

    td {
      font-size: 0.75rem !important;
    }
    
    tbody {
	    tr:nth-of-type(odd) {
		    th,
		    td {
			    background-color: rgba(42, 37, 33, 0.05);
		    }
		}
    }
  }

  .nav-tabs {
    .nav-item {
      .nav-link {
        font-family: "Montserrat", sans-serif !important;
        font-size: .875rem;
      }
    }
  }
}

@include media-breakpoint-down(md) {
	.dealers {
		.tab-content {
			width: 100%;
			overflow-x: scroll;
			
			td, th {
				white-space: nowrap;
			}
			
			th[colspan="8"] {
				padding-top: 25px !important;
			}
		}
		
		h2, h4 {
			font-size: 1.4rem;
		}
	}
}

#map {
  min-height: 75vh;
}
