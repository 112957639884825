/* Body ------------------------------------------------------------------------------------------------------------- */
body {
  min-height:       100vh;
  padding-top:      100px;

  &.small {
    padding-top:    50px;
  }
}

@include media-breakpoint-down(md) {
  body {
    padding-top:    50px;
  }
}


/* Header ----------------------------------------------------------------------------------------------------------- */
header {
  background-color: transparent;
  border-bottom:    1px solid $standard-text-color;
  height:           100px;
  position:         fixed;
  left:             0;
  right:            0;
  top:              0;
  transition:       all .25s ease;
  z-index:          1030;

  .logo-wrapper {
    max-width: 250px;
    margin: auto;
    transition:       all .25s ease;
  }

  .logo {
    max-height:     64px;
    transition:     all .5s ease;
  }

  &.small {
    border-bottom:  0;
    height:         50px;

    .logo-wrapper {
      max-width: 150px;
      margin: auto;
      transition:       all .25s ease;
    }

    .logo {
      max-height:   36px;
    };
  }

  &.invert {
    color: $white;
    border-bottom: 1px solid transparent;

    #topbar {
      a {
        color: $white !important;

        i {
          color: $white !important;
        }
      }

      .logo-wrapper {
        svg {
          path, rect {
            fill: #ffffff !important;
          }
        }
      }
    }
  }
}

//.invert {
//  header {
//    color: $white;
//    border-bottom: 1px solid $white;
//
//    #topbar {
//      a {
//        color: $white !important;
//
//        i {
//          color: $white !important;
//        }
//      }
//    }
//  }
//}

@include media-breakpoint-down(md) {
  header {
    height: 50px;

    .logo {
      max-height: 36px;
    };
  }
}

/* Breadcrumbs ------------------------------------------------------------------------------------------------------ */
.breadcrumb {
  background-color: transparent;
  font-size:        .75rem;
  padding:          5px 0;
  transition:       all .3s ease;

  .breadcrumb-item {
    a {
      color: $muted-text-color !important;

      &:hover {
        color: $standard-text-color !important;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  #sidebar {
    padding: 0;
  }
}


/* Content ---------------------------------------------------------------------------------------------------------- */
@include media-breakpoint-up(lg) {
  .carousel-control-next, .carousel-control-prev {
    width: 140px;
  }
}

main {
  padding-bottom: 5rem;
  overflow-x: hidden;

  .container {
    max-width: 1680px;
  }

  .container-standard {
    max-width: 1140px;
  }
}

@include media-breakpoint-down(md) {
  main {
    padding-bottom: 3rem;
  }
}

/* Social Media ----------------------------------------------------------------------------------------------------- */
.social-media {
  p {
    color: $beige;
    font-size: .75rem;
  }

  .img-thumbnail {
    width: 40px;
    height: 40px;
  }
}


/* Footer ----------------------------------------------------------------------------------------------------------- */
footer {
  background-color: $footer-background-color;
  color:            transparentize($footer-text-color, .25);
  font-size:        .875rem;
  min-height:       300px;
  padding:          5rem 1rem;

  .nav {
    .nav-item {
      .nav-link {
        color: transparentize($footer-text-color, .35);
        font-size: .875rem;
        font-weight: 300;
        letter-spacing: .5px;
        padding: 0;
      }

      &:hover, &.active {
        .nav-link {
          color: $white;
        }
      }
    }
  }

  h5 {
    color: transparentize($footer-text-color, .2);
    font-family: $headline-font;
    font-size: .9rem;
    font-weight: 300;
    letter-spacing: 1px;
    margin-bottom: 5px;
    text-transform: uppercase;
  }

  hr {
    background-color: transparentize($footer-text-color, .8);
    margin: 0 30px 4px 0;
  }

  input.newsletter-email {
    color: $beige !important;
  }

  input[type="email"]:focus {
    border-color: $beige;
    box-shadow: none;
    outline: 0 none;
  }

  input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba(239, 239, 239, .65) !important;
    opacity: 1; /* Firefox */
  }

  input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: rgba(239, 239, 239, .65) !important;
  }

  input::-ms-input-placeholder { /* Microsoft Edge */
    color: rgba(239, 239, 239, .65) !important;
  }

  .btn-outline-secondary {
    color: rgba(239, 239, 239, .65) !important;

    &:hover {
      background-color: $beige !important;
      color: $standard-text-color !important;
    }
  }
  
  .footercopyright {
	  font-weight: 300;
  }

}

/* cookie banner */
.cc-window {
	border-radius: 10px !important;
	max-width: 28em;
	
	.cc-btn {
		position: relative !important;
		display: inline-block !important;
		border-radius: 25px !important;
		height: 38px !important;
		border: 1px solid #2a2521 !important;
		text-transform: uppercase !important;
		font-family: Open Sans,Helvetica Neue,Arial,sans-serif !important;
		font-weight: 300 !important;
		color: #212529 !important;
		text-decoration: none !important;
		letter-spacing: 0.15rem !important;
		overflow: hidden;
		padding-left: 20px;
		padding-right: 20px;
		flex: 0 0 auto !important;
		
		&:hover {
			background-color: #212529 !important;
			color: #fff !important;
		}
	}
}
/* end of cookie banner */

@include media-breakpoint-down(md) {
	footer {
		.mt-3 {
			margin-top: 3rem !important;
		}
	}
}

@include media-breakpoint-down(sm) {
  footer {
    hr {
      margin: 0 0 4px 0;
    }
  }
}

/* Back Top---------------------------------------------------------------------------------------------------------- */
.back-top {
  background-color: $footer-background-color;
  min-height: 50px;

  #js-btn-scroll-top {
    background-color: inherit;
    color: $footer-text-color;
    cursor: pointer;
    margin-bottom: 2rem;
    border: 0;
  }
}

/* Video Block---------------------------------------------------------------------------------------------------------- */

.inline-video {
	width: 1450px;
	max-width: 100%;
	height: auto;
	margin: 0 auto;
	margin-bottom: 0px;
	display: block;
	margin-bottom: 3rem;
	margin-top: 5rem;
}

/* style cookie banner 2023 */

.ccm-root input[type="checkbox"].ccm-checkbox-type--switch:checked + label::before {
	background-color: #635a56 !important;
}

.ccm-root input[type="checkbox"].ccm-checkbox-type--switch:checked + label::after {
	background-color: #cca266 !important;
}

#ccm-widget, #ccm-control-panel {
	font-family:Montserrat,Helvetica Neue,Arial,sans-serif !important;
	font-weight:300 !important;
	
	

	
	.ccm-dismiss-button {
		display: none !important;
	}
	
	.ccm-modal-inner {
		border: none !important;
		border: 1px solid #ebdbd1 !important;
		border-radius: 20px !important;
		background-color: #413835 !important;
		background-image: linear-gradient(0deg, rgba(65, 56, 53, 1) 35%, rgba(140, 130, 124, 1) 100%) !important;
	}
	
	
	.ccm-modal--body,
	.ccm-modal--footer {
		background-color: transparent !important;
		padding-bottom: 25px !important;
	}
	
	.ccm-modal--footer .ccm-link-container a::before {
		display: none !important;
	}
	
	.ccm-modal--footer .ccm-link-container a {
		opacity: 0.5 !important;
	}
	
	.ccm-modal--body {
		padding-bottom: 0 !important;
		text-align: center;
		
	}
	.ccm-modal--body * {
	  color: #ebdbd1 !important;
	}
	
	.ccm-link-container a {
		background-color: transparent !important;
		color: #ebdbd1 !important;
	}
	
	.ccm-widget--title,
	.ccm-modal--title {
		font-family:Montserrat,Helvetica Neue,Arial,sans-serif !important;
		font-weight:300 !important;
		margin-left: auto;
		margin-right: auto;
		width: 60%;
	}
	
	.ccm-modal--header {
		background-color: transparent !important;
		  color: #ebdbd1 !important;
		  text-align: center !important;
	}
	
	.ccm--p {
		line-height: 1.6rem !important;
	}
	
	.ccm-dismiss-button {
		right: 10px !important;
		top: 10px !important;
		width: 35px !important;
		height: 35px !important;
	}

	.ccm-widget--buttons {
		margin-bottom: 0 !important;
	}
	
	.ccm-widget--buttons, .ccm-modal--footer {
		justify-content: center !important;
		gap: 15px !important;
		
		button {
				position: relative;
				display: inline-block !important;
				border-radius: 25px !important;
				height: 40px !important;
				padding: 20px 30px !important;
				line-height: 0 !important;
				font-size: 0.9rem !important;
				border: 1px solid #2a2521 !important;
				border: 0 !important;
				text-transform: uppercase !important;
				letter-spacing: 0.05rem !important;
				font-weight: 300 !important;
				margin: 15px 0px 30px 0px;
				overflow: hidden;
				background-color: #635a56 !important;
				color: #000 !important;
				z-index: 10 !important;
				white-space: nowrap !important;
				flex-basis: auto !important;
		
				span {
					z-index: 1;
					position: relative;
				}
				
				&::before,
				&::after {
					content: '';
					width: 0;
					height:100%;
					position: absolute;
					right: 49%;
					top: 0;
					transition: width 0.3s;
					z-index: -1;
				}
				
				&::after {
					right: auto;
					left: 49%;
				}
				
				
					&:hover {
						color: #000 !important;
						background-color: transparent !important;

						&::before,
						&::after {
							background-color: #b0a099;
						}
					}
				
			
				&:hover::before,
				&:hover::after {
					width: 52%;
				}
		}
		
		.ccm--button-primary {
			background-color: #cca266 !important;
			
			&:hover {
				&::before,
				&::after {
					background-color: #cfbfb8 !important;
				}
			}
		}
		
	}
	
	
}

@include media-breakpoint-down(sm) {
  #ccm-widget, #ccm-control-panel {
	  .ccm-modal--body {
		  padding: 20px 15px !important;
	  }
	  
	  .ccm-control-panel--purpose {
		.ccm-control-panel--purpose--description {
			font-size: 0.6 rem !important;
		}
	  	label {
		  font-size: 15px !important;
	  	}
	  }
	  
	  .ccm-widget--title,
	  .ccm-modal--title {
		  width: 100%;
		  font-size: 22px !important;
		  line-height: 26px !important;
		  margin-bottom: 10px !important;
	  }
	  .ccm--p {
		  line-height: 0.85rem !important;
		  font-size: 0.6rem !important;
	  }
	  .ccm-modal-inner {
		  max-height: none !important;
		  width: 96% !important;
		  left: 2% !important;
		  bottom: 15px !important;
	  }
	  .ccm-widget--buttons, .ccm-modal--footer {
		  button {
			  margin: 0 !important;
		  }
	  }
	  
	  .ccm-link-container {
		  font-size: .6rem !important;
	  }
	  
	  .ccm-widget--buttons button,
	  .ccm-modal--footer button {
		  border-radius: 10px !important;
		  font-size: .7rem !important;
		  padding: 0px 15px !important;
		  
		  &.ccm--button-primary {
			  width: 255px;
		  }
	  }
	  .ccm-inner-overflow {
		  padding-bottom: 15px !important;
	  }
	  .ccm-modal--body {
		  padding-bottom: 10px !important;
	  }
  }
}