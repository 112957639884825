@font-face {
  font-family: "Montserrat-ThinItalic";
  src: url('../../../public/assets/fonts/montserrat/Montserrat-ThinItalic.ttf') format('truetype');
}
@font-face {
  font-family: "Montserrat-Thin";
  src: url('../../../public/assets/fonts/montserrat/Montserrat-Thin.ttf') format('truetype');
}
@font-face {
  font-family: "Montserrat-SemiBoldItalic";
  src: url('../../../public/assets/fonts/montserrat/Montserrat-SemiBoldItalic.ttf') format('truetype');
}
@font-face {
  font-family: "Montserrat-SemiBold";
  src: url('../../../public/assets/fonts/montserrat/Montserrat-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: "Montserrat-Regular";
  src: url('../../../public/assets/fonts/montserrat/Montserrat-Regular.ttf') format('truetype');
}
@font-face {
  font-family: "Montserrat-MediumItalic";
  src: url('../../../public/assets/fonts/montserrat/Montserrat-MediumItalic.ttf') format('truetype');
}
@font-face {
  font-family: "Montserrat-Medium";
  src: url('../../../public/assets/fonts/montserrat/Montserrat-Medium.ttf') format('truetype');
}
@font-face {
  font-family: "Montserrat-LightItalic";
  src: url('../../../public/assets/fonts/montserrat/Montserrat-LightItalic.ttf') format('truetype');
}
@font-face {
  font-family: "Montserrat-Light";
  src: url('../../../public/assets/fonts/montserrat/Montserrat-Light.ttf') format('truetype');
}
@font-face {
  font-family: "Montserrat-Italic";
  src: url('../../../public/assets/fonts/montserrat/Montserrat-Italic.ttf') format('truetype');
}
@font-face {
  font-family: "Montserrat-ExtraLightItalic";
  src: url('../../../public/assets/fonts/montserrat/Montserrat-ExtraLightItalic.ttf') format('truetype');
}
@font-face {
  font-family: "Montserrat-ExtraLight";
  src: url('../../../public/assets/fonts/montserrat/Montserrat-ExtraLight.ttf') format('truetype');
}
@font-face {
  font-family: "Montserrat-ExtraBoldItalic";
  src: url('../../../public/assets/fonts/montserrat/Montserrat-ExtraBoldItalic.ttf') format('truetype');
}
@font-face {
  font-family: "Montserrat-ExtraBold";
  src: url('../../../public/assets/fonts/montserrat/Montserrat-ExtraBold.ttf') format('truetype');
}
@font-face {
  font-family: "Montserrat-BoldItalic";
  src: url('../../../public/assets/fonts/montserrat/Montserrat-BoldItalic.ttf') format('truetype');
}
@font-face {
  font-family: "Montserrat-Bold";
  src: url('../../../public/assets/fonts/montserrat/Montserrat-Bold.ttf') format('truetype');
}
@font-face {
  font-family: "Montserrat-BlackItalic";
  src: url('../../../public/assets/fonts/montserrat/Montserrat-BlackItalic.ttf') format('truetype');
}
@font-face {
  font-family: "Montserrat-Black";
  src: url('../../../public/assets/fonts/montserrat/Montserrat-Black.ttf') format('truetype');
}
