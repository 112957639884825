@include media-breakpoint-only(xs) {
  .breakpoint-alias:before {
    content: "XS";
  }
}
@include media-breakpoint-only(sm) {
  .breakpoint-alias:before {
    content: "SM";
  }
}
@include media-breakpoint-only(md) {
  .breakpoint-alias:before {
    content: "MD";
  }
}
@include media-breakpoint-only(lg) {
  .breakpoint-alias:before {
    content: "LG";
  }
}
@include media-breakpoint-only(xl) {
  .breakpoint-alias:before {
    content: "XL";
  }
}