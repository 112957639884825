@import "bootstrap_override";
@import "~bootstrap/scss/bootstrap";

/* SCSS ------------------------------------------------------------------------------------------------------------- */
@import "variables";
@import "core";
@import "layout";
@import "typography";
@import "helpers";
@import "offcanvas";
@import "slick-theme";

/* Components ------------------------------------------------------------------------------------------------------- */
@import "components/catalog";
@import "components/contact_form";
@import "components/history";
@import "components/navigation";
@import "components/tabbed_navigation";
@import "components/content_item";
@import "components/product";
@import "components/account";
@import "components/dealers";
@import "components/content_slider";
@import "components/content_fullpage-slider";

/* Grids ------------------------------------------------------------------------------------------------------------ */
@import "grids/breakpoints";
@import "grids/grid-ratio";
@import "grids/default_grid";
@import "grids/layout_3_3";
@import "grids/overlay";
@import "grids/masonry";
/* Mixins ----------------------------------------------------------------------------------------------------------- */

/* Product Request -------------------------------------------------------------------------------------------------- */
@import "MontserratWebfontsKit.css";
@import "components/product_request";

/* Filter Detail ---------------------------------------------------------------------------------------------------- */
@import "components/filter_detail";
/* Mixins ----------------------------------------------------------------------------------------------------------- */

/* NewsRoom --------------------------------------------------------------------------------------------------------- */
@import "components/newsroom";
@import "components/newsroom-overview";
/* Mixins ----------------------------------------------------------------------------------------------------------- */

@include media-breakpoint-up(lg) {
  .carousel-item {
    video {
      min-width: 100vw !important;
      min-height: 100vh !important;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      overflow: hidden;
    }
  }
}

@include media-breakpoint-down(md) {
  .carousel-item {
    video {
      -webkit-transform: translateX(-50%) translateY(-50%);
      -moz-transform: translateX(-50%) translateY(-50%);
      -ms-transform: translateX(-50%) translateY(-50%);
      -o-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
      position: absolute;
      top: 50%;
      left: 50%;
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      z-index: -100;
    }
  }
}

.carousel-indicators {
  z-index: 1000 !important;
}

#content {
	padding-left: 1rem !important;
	padding-right: 1rem !important;
}

body {
	overflow-x: hidden !important;
}

.hidden {
  display: none;
}

.flex-container,
.dFlex {
  display: flex;
}

.flexBasis100 {
	flex-basis: 100%;
}

.flexBasis50 {
	flex-basis: 50%;
}

.mtb-45 {
	margin: 45px 0;
}

.request-product-button {
  padding: 16px;
  font-size: 1rem;
  color: #fff !important;
  border-radius: 6px !important;
  border: 0 !important;

  &:hover {
	  background-color: #555 !important;
  }
}


