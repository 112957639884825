.grid_default {
  display: grid;
  grid-template-rows: auto;
  grid-gap: 0;
  align-items: stretch;
}

.grid-item {
  background-color: #ffffff;
  cursor: pointer;
  padding: .5rem;
}

// Desktop XL
//@include media-breakpoint-only(xl)
//{
//  .grid_default {
//    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
//
//    .highlight {
//      display: none;
//    }
//
//    .c1 {
//      grid-row: 1;
//      grid-column: 1;
//    }
//
//    .c2 {
//      grid-row: 1;
//      grid-column: 2;
//    }
//
//    .c3 {
//      grid-row: 1;
//      grid-column: 3;
//    }
//
//    .c4 {
//      grid-row: 1;
//      grid-column: 4;
//    }
//
//    .c5 {
//      grid-row: 1;
//      grid-column: 5;
//    }
//
//    .c6 {
//      grid-row: 1;
//      grid-column: 6;
//    }
//
//    .c7 {
//      grid-row: 2;
//      grid-column: 1;
//    }
//
//    .c8 {
//      grid-row: 2;
//      grid-column: 2;
//    }
//
//    .c9 {
//      grid-row: 2;
//      grid-column: 3;
//    }
//
//    .c10 {
//      grid-row: 2;
//      grid-column: 4;
//    }
//
//    .c11 {
//      grid-row: 2;
//      grid-column: 5;
//    }
//
//    .c12 {
//      grid-row: 2;
//      grid-column: 6;
//    }
//
//    .c13 {
//      grid-row: 3;
//      grid-column: 1;
//    }
//
//    .c14 {
//      grid-row: 3;
//      grid-column: 2;
//    }
//
//    .c15 {
//      grid-row: 3;
//      grid-column: 3;
//    }
//
//    .c16 {
//      grid-row: 3;
//      grid-column: 4;
//    }
//
//    .c17 {
//      grid-row: 3;
//      grid-column: 5;
//    }
//
//    .c18 {
//      grid-row: 3;
//      grid-column: 6;
//    }
//
//    .c19 {
//      grid-row: 4;
//      grid-column: 1;
//    }
//
//    .c20 {
//      grid-row: 4;
//      grid-column: 2;
//    }
//
//    .c21 {
//      grid-row: 4;
//      grid-column: 3;
//    }
//
//    .c22 {
//      grid-row: 4;
//      grid-column: 4;
//    }
//
//    .c23 {
//      grid-row: 4;
//      grid-column: 5;
//    }
//
//    .c24 {
//      grid-row: 4;
//      grid-column: 6;
//    }
//
//    // Overlays ----------------------------------------------------------------------------------------------------------
//    .overlay1 {
//      grid-row: 1;
//      grid-column: 1 / span 2;
//    }
//
//    .overlay2 {
//      grid-row: 1;
//      grid-column: 2 / span 2;
//    }
//
//    .overlay3 {
//      grid-row: 1;
//      grid-column: 3 / span 2;
//    }
//
//    .overlay4 {
//      grid-row: 1;
//      grid-column: 4 / span 2;
//    }
//
//    .overlay5 {
//      grid-row: 1;
//      grid-column: 5 / span 2;
//    }
//
//    .overlay6 {
//      grid-row: 1;
//      grid-column: 5 / span 2;
//    }
//
//    .overlay7 {
//      grid-row: 2;
//      grid-column: 1 / span 2;
//    }
//
//    .overlay8 {
//      grid-row: 2;
//      grid-column: 2 / span 2;
//    }
//
//    .overlay9 {
//      grid-row: 2;
//      grid-column: 3 / span 2;
//    }
//
//    .overlay10 {
//      grid-row: 2;
//      grid-column: 4 / span 2;
//    }
//
//    .overlay11 {
//      grid-row: 2;
//      grid-column: 5 / span 2;
//    }
//
//    .overlay12 {
//      grid-row: 2;
//      grid-column: 5 / span 2;
//    }
//
//    .overlay13 {
//      grid-row: 3;
//      grid-column: 1 / span 2;
//    }
//
//    .overlay14 {
//      grid-row: 3;
//      grid-column: 2 / span 2;
//    }
//
//    .overlay15 {
//      grid-row: 3;
//      grid-column: 3 / span 2;
//    }
//
//    .overlay16 {
//      grid-row: 3;
//      grid-column: 4 / span 2;
//    }
//
//    .overlay17 {
//      grid-row: 3;
//      grid-column: 5 / span 2;
//    }
//
//    .overlay18 {
//      grid-row: 3;
//      grid-column: 5 / span 2;
//    }
//
//    .overlay19 {
//      grid-row: 4;
//      grid-column: 1 / span 2;
//    }
//
//    .overlay20 {
//      grid-row: 4;
//      grid-column: 2 / span 2;
//    }
//
//    .overlay21 {
//      grid-row: 4;
//      grid-column: 3 / span 2;
//    }
//
//    .overlay22 {
//      grid-row: 4;
//      grid-column: 4 / span 2;
//    }
//
//    .overlay23 {
//      grid-row: 4;
//      grid-column: 5 / span 2;
//    }
//
//    .overlay24 {
//      grid-row: 4;
//      grid-column: 5 / span 2;
//    }
//  }
//}

// Desktop LG
@include media-breakpoint-up(lg)
{
  .grid_default {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin: 0 auto;

    .c1 {
      grid-row: 1;
      grid-column: 1;
    }

    .c2 {
      grid-row: 1;
      grid-column: 2;
    }

    .c3 {
      grid-row: 1;
      grid-column: 3;
    }

    .c4 {
      grid-row: 1;
      grid-column: 4;
    }

    .c5 {
      grid-row: 2;
      grid-column: 1;
    }

    .c6 {
      grid-row: 2;
      grid-column: 2;
    }

    .c7 {
      grid-row: 2;
      grid-column: 3;
    }

    .c8 {
      grid-row: 2;
      grid-column: 4;
    }

    .c9 {
      grid-row: 3;
      grid-column: 1;
    }

    .c10 {
      grid-row: 3;
      grid-column: 2;
    }

    .c11 {
      grid-row: 3;
      grid-column: 3;
    }

    .c12 {
      grid-row: 3;
      grid-column: 4;
    }

    .c13 {
      grid-row: 4;
      grid-column: 1;
    }

    .c14 {
      grid-row: 4;
      grid-column: 2;
    }

    .c15 {
      grid-row: 4;
      grid-column: 3;
    }

    .c16 {
      grid-row: 4;
      grid-column: 4;
    }

    .c17 {
      grid-row: 5;
      grid-column: 1;
    }

    .c18 {
      grid-row: 5;
      grid-column: 2;
    }

    .c19 {
      grid-row: 5;
      grid-column: 3;
    }

    .c20 {
      grid-row: 5;
      grid-column: 4;
    }

    .c21 {
      grid-row: 6;
      grid-column: 1;
    }

    .c22 {
      grid-row: 6;
      grid-column: 2;
    }

    .c23 {
      grid-row: 6;
      grid-column: 3;
    }

    .c24 {
      grid-row: 6;
      grid-column: 4;
    }

    // Overlays ----------------------------------------------------------------------------------------------------------
    .overlay1 {
      grid-row: 1;
      grid-column: 1 / span 2;
    }

    .overlay2 {
      grid-row: 1;
      grid-column: 2 / span 2;
    }

    .overlay3 {
      grid-row: 1;
      grid-column: 3 / span 2;
    }

    .overlay4 {
      grid-row: 1;
      grid-column: 3 / span 2;
    }

    .overlay5 {
      grid-row: 2;
      grid-column: 1 / span 2;
    }

    .overlay6 {
      grid-row: 2;
      grid-column: 2 / span 2;
    }

    .overlay7 {
      grid-row: 2;
      grid-column: 3 / span 2;
    }

    .overlay8 {
      grid-row: 2;
      grid-column: 3 / span 2;
    }

    .overlay9 {
      grid-row: 3;
      grid-column: 1 / span 2;
    }

    .overlay10 {
      grid-row: 3;
      grid-column: 2 / span 2;
    }

    .overlay11 {
      grid-row: 3;
      grid-column: 3 / span 2;
    }

    .overlay12 {
      grid-row: 3;
      grid-column: 3 / span 2;
    }

    .overlay13 {
      grid-row: 4;
      grid-column: 1 / span 2;
    }

    .overlay14 {
      grid-row: 4;
      grid-column: 2 / span 2;
    }

    .overlay15 {
      grid-row: 4;
      grid-column: 3 / span 2;
    }

    .overlay16 {
      grid-row: 4;
      grid-column: 3 / span 2;
    }

    .overlay17 {
      grid-row: 5;
      grid-column: 1 / span 2;
    }

    .overlay18 {
      grid-row: 5;
      grid-column: 2 / span 2;
    }

    .overlay19 {
      grid-row: 5;
      grid-column: 3 / span 2;
    }

    .overlay20 {
      grid-row: 5;
      grid-column: 3 / span 2;
    }

    .overlay21 {
      grid-row: 6;
      grid-column: 1 / span 2;
    }

    .overlay22 {
      grid-row: 6;
      grid-column: 2 / span 2;
    }

    .overlay23 {
      grid-row: 6;
      grid-column: 3 / span 2;
    }

    .overlay24 {
      grid-row: 6;
      grid-column: 3 / span 2;
    }
  }
}


// Tablet
@include media-breakpoint-only(md)
{
  .grid_default {
    grid-template-columns: 1fr 1fr 1fr;

    .c1 {
      grid-row: 1;
      grid-column: 1;

      .overlay {
        grid-column-start: span 2;
      }
    }

    .c2 {
      grid-row: 1;
      grid-column: 2;
    }

    .c3 {
      grid-row: 1;
      grid-column: 3;
    }

    .c4 {
      grid-row: 2;
      grid-column: 1;
    }

    .c5 {
      grid-row: 2;
      grid-column: 2;
    }

    .c6 {
      grid-row: 2;
      grid-column: 3;
    }

    .c7 {
      grid-row: 3;
      grid-column: 1;
    }

    .c8 {
      grid-row: 3;
      grid-column: 2;
    }

    .c9 {
      grid-row: 3;
      grid-column: 3;
    }

    .c10 {
      grid-row: 4;
      grid-column: 1;
    }

    .c11 {
      grid-row: 4;
      grid-column: 2;
    }

    .c12 {
      grid-row: 4;
      grid-column: 3;
    }

    .c13 {
      grid-row: 5;
      grid-column: 1;
    }

    .c14 {
      grid-row: 5;
      grid-column: 2;
    }

    .c15 {
      grid-row: 5;
      grid-column: 3;
    }

    .c16 {
      grid-row: 6;
      grid-column: 1;
    }

    .c17 {
      grid-row: 6;
      grid-column: 2;
    }

    .c18 {
      grid-row: 6;
      grid-column: 3;
    }

    .c19 {
      grid-row: 7;
      grid-column: 1;
    }

    .c20 {
      grid-row: 7;
      grid-column: 2;
    }

    .c21 {
      grid-row: 7;
      grid-column: 3;
    }

    .c22 {
      grid-row: 8;
      grid-column: 1;
    }

    .c23 {
      grid-row: 8;
      grid-column: 2;
    }

    .c24 {
      grid-row: 8;
      grid-column: 3;
    }

    // Overlays ----------------------------------------------------------------------------------------------------------
    .overlay1 {
      grid-row: 1;
      grid-column: 1 / span 2;
    }

    .overlay2 {
      grid-row: 1;
      grid-column: 2 / span 2;
    }

    .overlay3 {
      grid-row: 1;
      grid-column: 2 / span 2;
    }

    .overlay4 {
      grid-row: 1;
      grid-column: 1 / span 2;
    }

    .overlay5 {
      grid-row: 2;
      grid-column: 2 / span 2;
    }

    .overlay6 {
      grid-row: 2;
      grid-column: 2 / span 2;
    }

    .overlay7 {
      grid-row: 3;
      grid-column: 1 / span 2;
    }

    .overlay8 {
      grid-row: 3;
      grid-column: 2 / span 2;
    }

    .overlay9 {
      grid-row: 3;
      grid-column: 2 / span 2;
    }

    .overlay10 {
      grid-row: 4;
      grid-column: 1 / span 2;
    }

    .overlay11 {
      grid-row: 4;
      grid-column: 2 / span 2;
    }

    .overlay12 {
      grid-row: 4;
      grid-column: 2 / span 2;
    }

    .overlay13 {
      grid-row: 5;
      grid-column: 1 / span 2;
    }

    .overlay14 {
      grid-row: 5;
      grid-column: 2 / span 2;
    }

    .overlay15 {
      grid-row: 5;
      grid-column: 2 / span 2;
    }

    .overlay16 {
      grid-row: 6;
      grid-column: 1 / span 2;
    }

    .overlay17 {
      grid-row: 6;
      grid-column: 2 / span 2;
    }

    .overlay18 {
      grid-row: 6;
      grid-column: 2 / span 2;
    }

    .overlay19 {
      grid-row: 7;
      grid-column: 1 / span 2;
    }

    .overlay20 {
      grid-row: 7;
      grid-column: 2 / span 2;
    }

    .overlay21 {
      grid-row: 7;
      grid-column: 2 / span 2;
    }

    .overlay22 {
      grid-row: 8;
      grid-column: 1 / span 2;
    }

    .overlay23 {
      grid-row: 8;
      grid-column: 2 / span 2;
    }

    .overlay24 {
      grid-row: 8;
      grid-column: 2 / span 2;
    }
  }
}


// Small Devices
@include media-breakpoint-only(sm)
{
  .grid_default {
    grid-template-columns: 1fr 1fr;

    .c1 {
      grid-row: 1;
      grid-column: 1;

      .overlay {
        grid-column-start: span 2;
      }
    }

    .c2 {
      grid-row: 1;
      grid-column: 2;
    }

    .c3 {
      grid-row: 2;
      grid-column: 1;
    }

    .c4 {
      grid-row: 2;
      grid-column: 2;
    }

    .c5 {
      grid-row: 3;
      grid-column: 1;
    }

    .c6 {
      grid-row: 3;
      grid-column: 2;
    }

    .c7 {
      grid-row: 4;
      grid-column: 1;
    }

    .c8 {
      grid-row: 4;
      grid-column: 2;
    }

    .c9 {
      grid-row: 5;
      grid-column: 1;
    }

    .c10 {
      grid-row: 5;
      grid-column: 2;
    }

    .c11 {
      grid-row: 6;
      grid-column: 1;
    }

    .c12 {
      grid-row: 6;
      grid-column: 2;
    }

    .c13 {
      grid-row: 7;
      grid-column: 1;
    }

    .c14 {
      grid-row: 7;
      grid-column: 2;
    }

    .c15 {
      grid-row: 8;
      grid-column: 1;
    }

    .c16 {
      grid-row: 8;
      grid-column: 2;
    }

    .c17 {
      grid-row: 9;
      grid-column: 1;
    }

    .c18 {
      grid-row: 9;
      grid-column: 2;
    }

    .c19 {
      grid-row: 10;
      grid-column: 1;
    }

    .c20 {
      grid-row: 10;
      grid-column: 2;
    }

    .c21 {
      grid-row: 11;
      grid-column: 1;
    }

    .c22 {
      grid-row: 11;
      grid-column: 2;
    }

    .c23 {
      grid-row: 12;
      grid-column: 1;
    }

    .c24 {
      grid-row: 12;
      grid-column: 2;
    }

    // Overlays ----------------------------------------------------------------------------------------------------------
    .overlay1 {
      grid-row: 1;
      grid-column: 1 / span 2;
    }

    .overlay2 {
      grid-row: 1;
      grid-column: 1 / span 2;
    }

    .overlay3 {
      grid-row: 2;
      grid-column: 1 / span 2;
    }

    .overlay4 {
      grid-row: 2;
      grid-column: 1 / span 2;
    }

    .overlay5 {
      grid-row: 3;
      grid-column: 1 / span 2;
    }

    .overlay6 {
      grid-row: 3;
      grid-column: 1 / span 2;
    }

    .overlay7 {
      grid-row: 4;
      grid-column: 1 / span 2;
    }

    .overlay8 {
      grid-row: 4;
      grid-column: 1 / span 2;
    }

    .overlay9 {
      grid-row: 5;
      grid-column: 1 / span 2;
    }

    .overlay10 {
      grid-row: 5;
      grid-column: 1 / span 2;
    }

    .overlay11 {
      grid-row: 6;
      grid-column: 1 / span 2;
    }

    .overlay12 {
      grid-row: 6;
      grid-column: 1 / span 2;
    }

    .overlay13 {
      grid-row: 7;
      grid-column: 1 / span 2;
    }

    .overlay14 {
      grid-row: 7;
      grid-column: 1 / span 2;
    }

    .overlay15 {
      grid-row: 8;
      grid-column: 1 / span 2;
    }

    .overlay16 {
      grid-row: 8;
      grid-column: 1 / span 2;
    }

    .overlay17 {
      grid-row: 9;
      grid-column: 1 / span 2;
    }

    .overlay18 {
      grid-row: 9;
      grid-column: 1 / span 2;
    }

    .overlay19 {
      grid-row: 10;
      grid-column: 1 / span 2;
    }

    .overlay20 {
      grid-row: 10;
      grid-column: 1 / span 2;
    }

    .overlay21 {
      grid-row: 11;
      grid-column: 1 / span 2;
    }

    .overlay22 {
      grid-row: 11;
      grid-column: 1 / span 2;
    }

    .overlay23 {
      grid-row: 12;
      grid-column: 1 / span 2;
    }

    .overlay24 {
      grid-row: 12;
      grid-column: 1 / span 2;
    }
  }
}


// Small Devices
@include media-breakpoint-only(xs)
{
  .grid_default {
    grid-template-columns: 1fr 1fr;

    .highlight {
      display: none;
    }

    .c1 {
      grid-row: 1;
      grid-column: 1;

      .overlay {
        grid-column-start: span 2;
      }
    }

    .c2 {
      grid-row: 1;
      grid-column: 2;
    }

    .c3 {
      grid-row: 2;
      grid-column: 1;
    }

    .c4 {
      grid-row: 2;
      grid-column: 2;
    }

    .c5 {
      grid-row: 3;
      grid-column: 1;
    }

    .c6 {
      grid-row: 3;
      grid-column: 2;
    }

    .c7 {
      grid-row: 4;
      grid-column: 1;
    }

    .c8 {
      grid-row: 4;
      grid-column: 2;
    }

    .c9 {
      grid-row: 5;
      grid-column: 1;
    }

    .c10 {
      grid-row: 5;
      grid-column: 2;
    }

    .c11 {
      grid-row: 6;
      grid-column: 1;
    }

    .c12 {
      grid-row: 6;
      grid-column: 2;
    }

    .c13 {
      grid-row: 7;
      grid-column: 1;
    }

    .c14 {
      grid-row: 7;
      grid-column: 2;
    }

    .c15 {
      grid-row: 8;
      grid-column: 1;
    }

    .c16 {
      grid-row: 8;
      grid-column: 2;
    }

    .c17 {
      grid-row: 9;
      grid-column: 1;
    }

    .c18 {
      grid-row: 9;
      grid-column: 2;
    }

    .c19 {
      grid-row: 10;
      grid-column: 1;
    }

    .c20 {
      grid-row: 10;
      grid-column: 2;
    }

    .c21 {
      grid-row: 11;
      grid-column: 1;
    }

    .c22 {
      grid-row: 11;
      grid-column: 2;
    }

    .c23 {
      grid-row: 12;
      grid-column: 1;
    }

    .c24 {
      grid-row: 12;
      grid-column: 2;
    }


    // Overlays ----------------------------------------------------------------------------------------------------------
    .overlay1 {
      grid-row: 1 / span 2;
      grid-column: 1 / span 2;
    }

    .overlay2 {
      grid-row: 1 / span 2;
      grid-column: 1 / span 2;
    }

    .overlay3 {
      grid-row: 2 / span 2;
      grid-column: 1 / span 2;
    }

    .overlay4 {
      grid-row: 2 / span 2;
      grid-column: 1 / span 2;
    }

    .overlay5 {
      grid-row: 3 / span 2;
      grid-column: 1 / span 2;
    }

    .overlay6 {
      grid-row: 3 / span 2;
      grid-column: 1 / span 2;
    }

    .overlay7 {
      grid-row: 4 / span 2;
      grid-column: 1 / span 2;
    }

    .overlay8 {
      grid-row: 4 / span 2;
      grid-column: 1 / span 2;
    }

    .overlay9 {
      grid-row: 5 / span 2;
      grid-column: 1 / span 2;
    }

    .overlay10 {
      grid-row: 5 / span 2;
      grid-column: 1 / span 2;
    }

    .overlay11 {
      grid-row: 6 / span 2;
      grid-column: 1 / span 2;
    }

    .overlay12 {
      grid-row: 6 / span 2;
      grid-column: 1 / span 2;
    }

    .overlay13 {
      grid-row: 7 / span 2;
      grid-column: 1 / span 2;
    }

    .overlay14 {
      grid-row: 7 / span 2;
      grid-column: 1 / span 2;
    }

    .overlay15 {
      grid-row: 8 / span 2;
      grid-column: 1 / span 2;
    }

    .overlay16 {
      grid-row: 8 / span 2;
      grid-column: 1 / span 2;
    }

    .overlay17 {
      grid-row: 11 / span 2;
      grid-column: 1 / span 2;
    }

    .overlay18 {
      grid-row: 11 / span 2;
      grid-column: 1 / span 2;
    }

    .overlay19 {
      grid-row: 12 / span 2;
      grid-column: 1 / span 2;
    }

    .overlay20 {
      grid-row: 12 / span 2;
      grid-column: 1 / span 2;
    }

    .overlay21 {
      grid-row: 11 / span 2;
      grid-column: 1 / span 2;
    }

    .overlay22 {
      grid-row: 11 / span 2;
      grid-column: 1 / span 2;
    }

    .overlay23 {
      grid-row: 12 / span 2;
      grid-column: 1 / span 2;
    }

    .overlay24 {
      grid-row: 12 / span 2;
      grid-column: 1 / span 2;
    }
  }
}
